import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import i18n from "i18next";
import { dateFormat, timeFormat } from "../../../../dynamicController";

const mySwal = withReactContent(Swal);

class SmsReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			loading: true,
			data: []
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated || !this.props.admin.admin || !this.props.admin.admin.sms_report) {
				this.props.history.push("/admin/dashboard");
			} else {
				const { operatorId } = this.props.admin.admin;
				axios
					.post("/api/admin/report/sms", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						this.setState({
							...this.state,
							loading: false,
							data: res.data
						});
					})
					.catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	render() {
		let data = {
			columns: [
				{
					label: i18n.t("Phone Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Message Sent"),
					field: "msg",
					sort: "asc"
				},
				{
					label: i18n.t("Date Sent"),
					field: "date",
					sort: "asc"
				}
			],
			rows: []
		};

		let csv = [];

		if (this.state.data.length > 0) {
			for (let i = 0; i < this.state.data.length; i++) {
				const dataObject = {
					number: this.state.data[i].number,
					msg: this.state.data[i].message,
					date: this.state.data[i].createdAt
				};
				data.rows.push(dataObject);

				const csvDataObject = {
					number: `=""${this.state.data[i].number}""`,
					msg: this.state.data[i].message,
					date: new Date(this.state.data[i].createdAt).toLocaleString("en-GB")
				}
				csv.push(csvDataObject);
			}
		}

		const csvData = {
			headers: [
				{ label: "Phone Number", key: "number" },
				{ label: "Message Sent", key: "msg" },
				{ label: "Date Sent", key: "date" }
			],
			data: csv
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header d-flex justify-content-between">
								<h5 className="card-title">{i18n.t("SMS Report")}</h5>
								<h4 className="m-0 p-0">{i18n.t("Remaining SMS Credit")}: {this.props.admin?.admin?.smsCredit || 0}</h4>
							</div>

							<div className="card-body text-center">
								{
									this.state.loading ? <Loading /> : (
										<div>
											<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
											<div className="d-grid gap-2 d-md-flex justify-content-md-end">
												<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`sms_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
													{i18n.t("Download Report")}
												</CSVLink>
											</div>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(SmsReport);
