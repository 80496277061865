import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { currency, dateFormat, timeFormat } from '../../../dynamicController';
import Loading from './Loading';

const mySwal = withReactContent(Swal);

const UserCompensation = ({ operatorId, authtoken, stamp, etoken, point, id, role, country, rewardHistoryPath }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [giveawayModal, setGiveawayModal] = useState(false);
    const [voucherModal, setVoucherModal] = useState(false);
    const [tokenModal, setTokenModal] = useState(false);
    const [stampModal, setStampModal] = useState(false);
    const [pointModal, setPointModal] = useState(false);

    const [userCountry, setUserCountry] = useState("");
    const [userId, setUserId] = useState("");
    const [remark, setRemark] = useState("Reward");
    const [tokenAmount, setTokenAmount] = useState(0);
    const [pointAmount, setPointAmount] = useState(0);

    const [washerStamp, setWasherStamp] = useState(0);
    const [dryerStamp, setDryerStamp] = useState(0);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [duration, setDuration] = useState("");
    const [type, setType] = useState("Flat");
    const [amount, setAmount] = useState("");
    const [washerUse, setWasherUse] = useState(false);
    const [dryerUse, setDryerUse] = useState(false);
    const [packageUse, setPackageUse] = useState(false);

    useEffect(() => {
        setLoading(true)
        axios
            .post("/api/admin/setting/user/reward", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
            .then(res => {
                setData(res.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                mySwal.fire(t("Error"), t(err.response.data.error), "error")
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openGiveawayModal = (userId, country) => {
        setUserId(userId)
        setUserCountry(country)
        setRemark("Reward")
        setTokenAmount(0)
        setPointAmount(0)
        setWasherStamp(0)
        setDryerStamp(0)
        setTitle("")
        setDescription("")
        setDuration("")
        setType("Flat")
        setAmount("")
        setWasherUse(false)
        setDryerUse(false)
        setPackageUse(false)
        setGiveawayModal(true)
    }

    const giveVoucher = (e) => {
        e.preventDefault();

        if (!title || !description || !amount || parseFloat(amount) <= 0) {
            mySwal.fire(t("Missing Field"), t("Please fill in all the required field. (Title, description, and amount must be more than 0)"), "info");
        } else if (!washerUse && !dryerUse && !packageUse) {
            mySwal.fire(t("Checkbox required"), t("Must select voucher can be used for washer, dryer or package"), "info");
        } else {
            setLoading(true)
            const sendThis = {
                id: userId,
                rewardBy: id,
                rewardType: "Voucher",
                title,
                description,
                duration,
                type,
                amount,
                washerUse,
                dryerUse,
                packageUse,
                remark,
                role
            }

            axios
                .post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/user/reward", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
                        .then(res => {
                            setData(res.data)
                            setLoading(false)
                            setVoucherModal(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const giveToken = (e) => {
        e.preventDefault();

        if (parseFloat(tokenAmount) <= 0) {
            mySwal.fire(t("Error"), t("Token value must be more than 0"), "error");
        } else {
            setLoading(true)
            const sendThis = {
                id: userId,
                rewardType: "Token",
                token: tokenAmount,
                rewardBy: id,
                remark,
                role
            }

            axios
                .post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/user/reward", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
                        .then(res => {
                            setData(res.data)
                            setLoading(false)
                            setTokenModal(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const giveStamp = (e) => {
        e.preventDefault();

        if (!parseInt(washerStamp) && !parseInt(dryerStamp)) {
            mySwal.fire(t("Error"), t("Please fill in at least one of the required field"), "error");
        } else if (parseInt(washerStamp) < 0 || parseInt(dryerStamp) < 0) {
            mySwal.fire(t("Error"), t("The value must be more than 0"), "error");
        } else {
            setLoading(true)
            const sendThis = {
                id: userId,
                rewardBy: id,
                washerStamp,
                dryerStamp,
                rewardType: "Stamp",
                remark,
                role
            }

            axios
                .post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/user/reward", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
                        .then(res => {
                            setData(res.data)
                            setLoading(false)
                            setStampModal(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const givePoint = (e) => {
        e.preventDefault();

        if (parseInt(pointAmount) <= 0) {
            mySwal.fire(t("Error"), t("The value must be more than 0"), "error");
        } else {
            setLoading(true)
            const sendThis = {
                id: userId,
                rewardBy: id,
                point: pointAmount,
                rewardType: "Point",
                remark,
                role
            }

            axios
                .post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/user/reward", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
                        .then(res => {
                            setData(res.data)
                            setLoading(false)
                            setPointModal(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    let csv = [];

    const csvData = {
        headers: [
            { label: "User Name", key: "name" },
            { label: "Phone Number", key: "number" },
            { label: "Email", key: "email" },
            { label: "Member ID", key: "memberId" },
            { label: "Rewarded Times", key: "rewardTimes" },
            { label: "Voucher Rewarded (times)", key: "voucherReward" }
        ],
        data: csv
    };

    let tableData = {
        columns: [
            {
                label: t("Action"),
                field: "action"
            },
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "memberId",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("How many times rewarded to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Rewarded Times")}</span>
                    </OverlayTrigger>
                ),
                field: "rewardTimes",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("How many times rewarded voucher to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Voucher Rewarded (times)")}</span>
                    </OverlayTrigger>
                ),
                field: "voucherReward",
                sort: "asc"
            }
        ],
        rows: []
    };

    if (stamp) {
        tableData.columns.push(
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("How many times rewarded stamp to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Stamps Rewarded (times)")}</span>
                    </OverlayTrigger>
                ),
                field: "stampReward",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of washer stamps rewarded to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Washer Stamps Granted (stamp)")}</span>
                    </OverlayTrigger>
                ),
                field: "washerStamp",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of dryer stamps rewarded to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Dryer Stamps Granted (stamp)")}</span>
                    </OverlayTrigger>
                ),
                field: "dryerStamp",
                sort: "asc"
            }
        );

        csvData.headers.push(
            { label: "Stamps Rewarded (times)", key: "stampReward" },
            { label: "Washer Stamps Granted (stamp)", key: "washerStamp" },
            { label: "Dryer Stamps Granted (stamp)", key: "dryerStamp" }
        )
    }

    if (etoken) {
        tableData.columns.push(
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("How many times rewarded e-Token to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("e-Token Rewarded (times)")}</span>
                    </OverlayTrigger>
                ),
                field: "tokenReward",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of e-Token rewarded to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Total e-Token Granted (token)")}</span>
                    </OverlayTrigger>
                ),
                field: "tokenGranted",
                sort: "asc"
            }
        );

        csvData.headers.push(
            { label: "e-Token Rewarded (times)", key: "tokenReward" },
            { label: "Total e-Token Granted (token)", key: "tokenGranted" }
        )
    }

    if (point) {
        tableData.columns.push(
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("How many times rewarded point to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Point Rewarded (times)")}</span>
                    </OverlayTrigger>
                ),
                field: "pointReward",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of point rewarded to user")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Total Point Granted (point)")}</span>
                    </OverlayTrigger>
                ),
                field: "pointGranted",
                sort: "asc"
            }
        );

        csvData.headers.push(
            { label: "Point Rewarded (times)", key: "pointReward" },
            { label: "Total Point Granted (point)", key: "pointGranted" }
        )
    }

    if (role === "client") {
        csvData.headers.unshift({ label: "Distributor", key: "distributorName" })
        tableData.columns.splice(1, 0, {
            label: t("Distributor"),
            field: "distributorName",
            sort: "asc"
        })
    }

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            let csvDataObject = {
                distributorName: data[i].distributorName,
                name: data[i].name,
                number: `=""${data[i].number}""`,
                email: data[i].email,
                memberId: data[i].memberId,
                rewardTimes: data[i].rewardedTimes,
                voucherReward: data[i].voucherReward,
                stampReward: data[i].stampReward,
                washerStamp: data[i].washerStamp,
                dryerStamp: data[i].dryerStamp,
                tokenReward: data[i].tokenReward,
                tokenGranted: data[i].tokenGranted,
                pointReward: data[i].pointReward,
                pointGranted: data[i].pointGranted,
            }
            csv.push(csvDataObject);

            const dataObject = {
                action: (
                    <button type="button" className="btn btn-primary" onClick={() => openGiveawayModal(data[i].id, data[i].country)}>
                        {t("Reward")}
                    </button>
                ),
                distributorName: data[i].distributorName,
                name: data[i].name,
                number: data[i].number,
                email: data[i].email,
                memberId: data[i].memberId,
                rewardTimes: data[i].rewardedTimes,
                voucherReward: data[i].voucherReward,
                stampReward: data[i].stampReward,
                washerStamp: data[i].washerStamp,
                dryerStamp: data[i].dryerStamp,
                tokenReward: data[i].tokenReward,
                tokenGranted: data[i].tokenGranted,
                pointReward: data[i].pointReward,
                pointGranted: data[i].pointGranted,
            };
            tableData.rows.push(dataObject);
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title">{t("Reward Giveaway")}</h5>
                    <Link className="btn btn-primary" to={rewardHistoryPath}>
                        {t("Reward History")}
                    </Link>
                </div>

                <div className="card-body text-center">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`reward_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <Modal
                show={giveawayModal}
                onHide={e => setGiveawayModal(false)}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Reward Giveaway")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>{t("Which type of reward you want to give to customer?")}</p>
                        <div className='d-flex justify-content-around'>
                            <button type="button" className="btn btn-primary" onClick={() => { setGiveawayModal(false); setVoucherModal(true) }}>
                                {t("Voucher")}
                            </button>
                            {
                                stamp ? (
                                    <button type="button" className="btn btn-primary" onClick={() => { setGiveawayModal(false); setStampModal(true) }}>
                                        {t("Stamp")}
                                    </button>
                                ) : null
                            }
                            {
                                etoken ? (
                                    <button type="button" className="btn btn-primary" onClick={() => { setGiveawayModal(false); setTokenModal(true) }}>
                                        {t("e-Token")}
                                    </button>
                                ) : null
                            }
                            {
                                point ? (
                                    <button type="button" className="btn btn-primary" onClick={() => { setGiveawayModal(false); setPointModal(true) }}>
                                        {t("Point")}
                                    </button>
                                ) : null
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={voucherModal}
                onHide={e => setVoucherModal(false)}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("VOUCHER")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={giveVoucher}>
                            <div className="text-left mt-4">
                                <div className="form-group">
                                    <label htmlFor="title">{t("Title")}</label>
                                    <input type="text" id="title" name="title" value={title} onChange={e => setTitle(e.target.value)} className="form-control" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">{t("Description")}</label>
                                    <input type="text" id="description" name="description" value={description} onChange={e => setDescription(e.target.value)} className="form-control" required />
                                </div>

                                <p className="mt-5 p-0 mb-0">{t("For machine uses (required)")}</p>
                                <div className="d-flex justify-content-around mt-3 mb-2">
                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="washerUse" name="washerUse" checked={washerUse} onChange={e => setWasherUse(!washerUse)} />
                                            <span>{t("Washer Use")}</span>
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="dryerUse" name="dryerUse" checked={dryerUse} onChange={e => setDryerUse(!dryerUse)} />
                                            <span>{t("Dryer Use")}</span>
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="packageUse" name="packageUse" checked={packageUse} onChange={e => setPackageUse(!packageUse)} />
                                            <span>{t("Package Use")}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="duration">{t("Voucher Lifespan")} ({t("days")})</label>
                                    <input type="text" id="duration" name="duration" value={duration} onChange={e => setDuration(e.target.value)} className="form-control" placeholder={t("leave blank if no expiry date")} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="type">{t("Discount Type")}</label>
                                    <select id="type" name="type" className="browser-default form-select" required value={type} onChange={e => setType(e.target.value)}>
                                        <option value="Flat">{t("Flat")} ({currency(country || userCountry)})</option>
                                        <option value="Rate">{t("Rate")} (%)</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="amount">{t("Voucher Amount")}</label>
                                    <input type="number" id="amount" name="amount" className="form-control" value={amount} onChange={e => setAmount(e.target.value)} min="0" max="100" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="remark">{t("Remarks")}</label>
                                    <select id="remark" name="remark" className="browser-default form-select" value={remark} onChange={e => setRemark(e.target.value)} required>
                                        <option value="Reward">{t("Reward")}</option>
                                        <option value="Refund">{t("Refund")}</option>
                                    </select>
                                </div>
                            </div>

                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <div className='d-flex justify-content-center'>
                                        <div>
                                            <button type="submit" className="btn btn-primary">
                                                {t("OK")}
                                            </button>
                                        </div>
                                        <div className='ms-3'>
                                            <button type="button" className="btn btn-secondary" onClick={() => setVoucherModal(false)}>
                                                {t("Cancel")}
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={tokenModal}
                onHide={e => setTokenModal(false)}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("E-TOKEN")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={giveToken}>
                            <div className="text-left mt-4">
                                <div className="form-group">
                                    <label htmlFor="token">{t("Number of e-Token")}</label>
                                    <input type="number" id="token" name="token" className="form-control" value={tokenAmount} onChange={e => setTokenAmount(e.target.value)} min="0" step="0.01" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="remark">{t("Remarks")}</label>
                                    <select id="remark" name="remark" className="browser-default form-select" value={remark} onChange={e => setRemark(e.target.value)} required>
                                        <option value="Reward">{t("Reward")}</option>
                                        <option value="Refund">{t("Refund")}</option>
                                    </select>
                                </div>
                            </div>

                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <div className='d-flex justify-content-center'>
                                        <div>
                                            <button type="submit" className="btn btn-primary">
                                                {t("OK")}
                                            </button>
                                        </div>
                                        <div className='ms-3'>
                                            <button type="button" className="btn btn-secondary" onClick={() => setTokenModal(false)}>
                                                {t("Cancel")}
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={stampModal}
                onHide={e => setStampModal(false)}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("STAMP")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={giveStamp}>
                            <div className="text-left mt-4">
                                <div className="form-group">
                                    <label htmlFor="washerStamp">{t("Washer Stamp")}</label>
                                    <input type="number" id="washerStamp" name="washerStamp" value={washerStamp} onChange={e => setWasherStamp(e.target.value)} className="form-control" min="0" max="10" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="dryerStamp">{t("Dryer Stamp")}</label>
                                    <input type="number" id="dryerStamp" name="dryerStamp" value={dryerStamp} onChange={e => setDryerStamp(e.target.value)} className="form-control" min="0" max="10" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="remark">{t("Remarks")}</label>
                                    <select id="remark" name="remark" className="browser-default form-select" value={remark} onChange={e => setRemark(e.target.value)} required>
                                        <option value="Reward">{t("Reward")}</option>
                                        <option value="Refund">{t("Refund")}</option>
                                    </select>
                                </div>
                            </div>

                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <div className='d-flex justify-content-center'>
                                        <div>
                                            <button type="submit" className="btn btn-primary">
                                                {t("OK")}
                                            </button>
                                        </div>
                                        <div className='ms-3'>
                                            <button type="button" className="btn btn-secondary" onClick={() => setStampModal(false)}>
                                                {t("Cancel")}
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={pointModal}
                onHide={e => setPointModal(false)}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("POINT")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={givePoint}>
                            <div className="text-left mt-4">
                                <div className="form-group">
                                    <label htmlFor="point">{t("Number of Point")}</label>
                                    <input type="number" id="point" name="point" className="form-control" value={pointAmount} onChange={e => setPointAmount(e.target.value)} min="0" step="1" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="remark">{t("Remarks")}</label>
                                    <select id="remark" name="remark" className="browser-default form-select" value={remark} onChange={e => setRemark(e.target.value)} required>
                                        <option value="Reward">{t("Reward")}</option>
                                        <option value="Refund">{t("Refund")}</option>
                                    </select>
                                </div>
                            </div>

                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <div className='d-flex justify-content-center'>
                                        <div>
                                            <button type="submit" className="btn btn-primary">
                                                {t("OK")}
                                            </button>
                                        </div>
                                        <div className='ms-3'>
                                            <button type="button" className="btn btn-secondary" onClick={() => setPointModal(false)}>
                                                {t("Cancel")}
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UserCompensation;