import { connect } from "react-redux";
import { changePage } from "../../../redux/actions/userActions";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useTranslation } from "react-i18next";
import CleanproCheck from "./component/Check/CleanproCheck";
import LaundroCheck from "./component/Check/LaundroCheck";
import WashupCheck from "./component/Check/WashupCheck";

const mySwal = withReactContent(Swal);
const STORETYPE = process.env.REACT_APP_STORETYPE;

const Check = ({ change, showButton, operatorId, outlet }) => {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const checkOperator = (e) => {
    e.preventDefault();

    axios
      .post("/api/user/auth/checkDisable", { operatorId: operatorId })
      .then((res) => {
        if (outlet.outletCode && outlet.operatorCode && !outlet.machineNo) {
          history.push(`/user/dng/payment${location.search}`);
        } else {
          history.push(`/user/payment${location.search}`);
        }
      })
      .catch((err) => {
        mySwal.fire(t("Error"), t(err.response.data.error), "error");
      });
  };

  return (
    <div>
      {
        STORETYPE === "cleanpro"
          ? <CleanproCheck change={change} showButton={showButton} checkOperator={checkOperator} />
          : STORETYPE === "washup"
            ? <WashupCheck change={change} showButton={showButton} checkOperator={checkOperator} />
            : <LaundroCheck change={change} showButton={showButton} checkOperator={checkOperator} />
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    operatorId: state.user.operatorId,
    outlet: state.payment.outlet
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (page) => dispatch(changePage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Check);
