import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from '../../components/Sidebar';
import Topbar from '../../components/Topbar';
import ReferralRewardHistoryReport from '../../components/ReferralRewardHistoryReport';

const AdminReferralRewardHistoryReport = ({ admin, token }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false)

    useEffect(() => {
        if (!admin || !admin.referral_reward_history_report) history.push("/admin/dashboard");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={e => setToggled(!toggled)} />
            <div className="admin-page">
                <Topbar handleToggle={e => setToggled(!toggled)} />
                <div className="admin-content">
                    <ReferralRewardHistoryReport
                        token={token}
                        role="operator"
                        stamp={admin?.stamp}
                        etoken={admin?.token}
                        point={admin?.loyalty_point}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        admin: state.admin.admin,
        token: state.admin.token
    };
};

export default connect(mapStateToProps, null)(AdminReferralRewardHistoryReport);