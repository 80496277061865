import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBDataTable } from "mdbreact";
import i18n from "i18next";

class TopOutlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "Transactions"
        };
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {

        let data = {
            columns: [
                {
                    label: i18n.t("Top Outlets"),
                    field: "topOutlets",
                    sort: "asc"
                },
                {
                    label: i18n.t("Users"),
                    field: "users",
                    sort: "asc"
                },
                {
                    label: i18n.t("Transactions"),
                    field: "transactions",
                    sort: "asc"
                }
            ],
            rows: []
        }

        if (this.state.filter === "Users") {

            data.rows = this.props.topOutletData.sortByUsers;

        } else if (this.state.filter === "Transactions") {

            data.rows = this.props.topOutletData.sortByTransactions;
        }

        return (
            <div>
                <div>
                    <h6 className="text-center">{i18n.t("Top Outlets")}</h6>
                    <div>
                        <label htmlFor="filter">{i18n.t("Sort by")}: </label>
                        <select id="filter" name="filter" className="browser-default form-select" value={this.state.filter} onChange={this.handleChange}>
                            <option value="Transactions">{i18n.t("Transactions")}</option>
                            <option value="Users">{i18n.t("Users")}</option>
                        </select>
                    </div>
                </div>

                <div className="text-center mt-3"><MDBDataTable data={data} paging={false} searching={false} sortable={false} noBottomColumns striped hover responsive bordered /></div>

            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        outlets: state.admin.admin.outlets
    };
};

export default connect(mapStateToProps, null)(TopOutlet);