import { useEffect } from 'react';
import { connect } from 'react-redux';
import StaffLogin from './pages/StaffLogin';
import StaffDashboard from './pages/StaffDashboard';
import { checkToken, staffTokenLogin, saveStaffRedirectUrl, saveStaffUserType } from "../../redux/actions/staffActions";
import { changeLanguage } from '../../redux/actions/userActions';
import queryString from 'query-string';


const Staff = ({ isAuthenticated, checkToken, location, tokenLogin, saveStaffRedirectUrl, saveStaffUserType, changeLanguage }) => {

    useEffect(() => {
        const { token, redirectUrl, userType, language } = queryString.parse(location.search);
        if (redirectUrl) {
            saveStaffRedirectUrl(redirectUrl)
        }
        if (userType) {
            saveStaffUserType(userType)
        }
        if (language) {
            changeLanguage(language)
        }
        if (token) {
            tokenLogin({ token });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Loyalty Login',
                'brand': process.env.REACT_APP_STORETYPE,
                'role': 'staff'
            })
        } else {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="staff-page">
            {
                isAuthenticated
                    ? <StaffDashboard />
                    : <StaffLogin />
            }
        </div>
    );

};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.staff.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        tokenLogin: token => dispatch(staffTokenLogin(token)),
        saveStaffRedirectUrl: url => dispatch(saveStaffRedirectUrl(url)),
        saveStaffUserType: data => dispatch(saveStaffUserType(data)),
        changeLanguage: (language) => dispatch(changeLanguage(language))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Staff);