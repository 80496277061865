import { Card, CardGroup } from 'react-bootstrap';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const SummaryData = ({ summaryData, admin }) => {

    const { t } = useTranslation();

    let userPercentage = 0;
    if (summaryData.users.thisWeekUsers === 0 && summaryData.users.lastWeekUsers === 0) {
        userPercentage = 0;
    } else if (summaryData.users.thisWeekUsers !== 0 && summaryData.users.lastWeekUsers === 0) {
        userPercentage = 100;
    } else {
        userPercentage = ((summaryData.users.thisWeekUsers - summaryData.users.lastWeekUsers) / summaryData.users.lastWeekUsers) * 100;
    }

    let stampPercentage = 0;
    if (summaryData.stamps.thisWeekStamps === 0 && summaryData.stamps.lastWeekStamps === 0) {
        stampPercentage = 0;
    } else if (summaryData.stamps.thisWeekStamps !== 0 && summaryData.stamps.lastWeekStamps === 0) {
        stampPercentage = 100;
    } else {
        stampPercentage = ((summaryData.stamps.thisWeekStamps - summaryData.stamps.lastWeekStamps) / summaryData.stamps.lastWeekStamps) * 100;
    }

    let voucherPercentage = 0;
    if (summaryData.vouchers.thisWeekVouchers === 0 && summaryData.vouchers.lastWeekVouchers === 0) {
        voucherPercentage = 0;
    } else if (summaryData.vouchers.thisWeekVouchers !== 0 && summaryData.vouchers.lastWeekVouchers === 0) {
        voucherPercentage = 100;
    } else {
        voucherPercentage = ((summaryData.vouchers.thisWeekVouchers - summaryData.vouchers.lastWeekVouchers) / summaryData.vouchers.lastWeekVouchers) * 100;
    }

    let transactionPercentage = 0;
    if (summaryData.transactions.thisWeekTransactions === 0 && summaryData.transactions.lastWeekTransactions === 0) {
        transactionPercentage = 0;
    } else if (summaryData.transactions.thisWeekTransactions !== 0 && summaryData.transactions.lastWeekTransactions === 0) {
        transactionPercentage = 100;
    } else {
        transactionPercentage = ((summaryData.transactions.thisWeekTransactions - summaryData.transactions.lastWeekTransactions) / summaryData.transactions.lastWeekTransactions) * 100;
    }


    return (
        <div>
            <CardGroup>
                <Card style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Card.Body>
                        <Card.Subtitle>{t("Registered Users")}</Card.Subtitle>
                        <Card.Title style={{ paddingTop: "10px" }}>{summaryData.users.totalRegisteredUsers}</Card.Title>
                        <p style={{ color: userPercentage >= 0 ? "green" : "red" }}><i className={userPercentage >= 0 ? "bi bi-arrow-up" : "bi bi-arrow-down"}></i> {Math.abs(userPercentage.toFixed(2))} %</p>
                        <div style={{ position: "absolute", top: "65px", right: "30px", color: "grey", fontSize: "40px" }}><i className="bi bi-people-fill"></i></div>
                    </Card.Body>
                </Card>
                <Card style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Card.Body>
                        <Card.Subtitle>{t("Vouchers")}</Card.Subtitle>
                        <Card.Title style={{ paddingTop: "10px" }}>{summaryData.vouchers.totalVouchers}</Card.Title>
                        <p style={{ color: voucherPercentage >= 0 ? "green" : "red" }}><i className={voucherPercentage >= 0 ? "bi bi-arrow-up" : "bi bi-arrow-down"}></i> {Math.abs(voucherPercentage.toFixed(2))} %</p>
                        <div style={{ position: "absolute", top: "65px", right: "30px", color: "grey", fontSize: "40px" }}><i className="bi bi-ticket-perforated"></i></div>
                    </Card.Body>
                </Card>
                {
                    admin.stamp ? (
                        <Card style={{ marginLeft: "10px", marginRight: "10px" }}>
                            <Card.Body>
                                <Card.Subtitle>{t("Stamps")}</Card.Subtitle>
                                <Card.Title style={{ paddingTop: "10px" }}>{summaryData.stamps.totalStamps}</Card.Title>
                                <p style={{ color: stampPercentage >= 0 ? "green" : "red" }}><i className={stampPercentage >= 0 ? "bi bi-arrow-up" : "bi bi-arrow-down"}></i> {Math.abs(stampPercentage.toFixed(2))} %</p>
                                <div style={{ position: "absolute", top: "65px", right: "30px", color: "grey", fontSize: "40px" }}><i className="bi bi-postage-fill"></i></div>
                            </Card.Body>
                        </Card>
                    ) : null
                }
                <Card style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Card.Body>
                        <Card.Subtitle>{t("Transactions")}</Card.Subtitle>
                        <Card.Title style={{ paddingTop: "10px" }}>{summaryData.transactions.totalTransactions}</Card.Title>
                        <p style={{ color: transactionPercentage >= 0 ? "green" : "red" }}><i className={transactionPercentage >= 0 ? "bi bi-arrow-up" : "bi bi-arrow-down"}></i> {Math.abs(transactionPercentage.toFixed(2))} %</p>
                        <div style={{ position: "absolute", top: "65px", right: "30px", color: "grey", fontSize: "40px" }}><i className="bi bi-coin"></i></div>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        admin: state.admin.admin
    };
};

export default connect(mapStateToProps, null)(SummaryData);