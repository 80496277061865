import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class TokenReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			loading: true,
			tableData: null,
			topChoice: null,
			totalAmount: 0,
			totalTokens: 0,
			outletId: "",
			startDate: "",
			endDate: ""
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated || !this.props.admin.admin || !this.props.admin.admin.token_report) {
				return this.props.history.push("/admin/dashboard");
			} else {
				const { operatorId } = this.props.admin.admin;
				axios
					.post("/api/admin/report/token", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						// console.log(res.data);
						if (this._isMounted) {
							this.setState({
								...this.state,
								loading: false,
								tableData: res.data.tableData,
								topChoice: res.data.topChoice,
								totalAmount: res.data.totalAmount,
								totalTokens: res.data.totalTokens
							});
						}
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
					});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleChange = e =>
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});

	handleDate = dates => {
		const [start, end] = dates;
		// console.log({start , end});
		this.setState({
			...this.state,
			startDate: start,
			endDate: end
		});
	};

	handleFilter = e => {
		const { outletId, startDate, endDate } = this.state;
		let sendThis = {
			operatorId: this.props.admin.admin.operatorId
		};

		if (outletId) {
			sendThis.outletId = outletId;
		}
		if (startDate && endDate) {
			sendThis.startDate = new Date(startDate);
			sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
		}
		this.setState({
			...this.state,
			loading: true
		});
		axios
			.post("/api/admin/report/token", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					tableData: res.data.tableData,
					topChoice: res.data.topChoice,
					totalAmount: res.data.totalAmount,
					totalTokens: res.data.totalTokens
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	handleReset = e => {
		this.setState({
			...this.state,
			loading: true,
			outletId: "",
			startDate: "",
			endDate: ""
		});
		const { operatorId } = this.props.admin.admin;
		axios
			.post("/api/admin/report/token", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				// console.log(res.data);
				if (this._isMounted) {
					this.setState({
						...this.state,
						loading: false,
						tableData: res.data.tableData,
						topChoice: res.data.topChoice,
						totalAmount: res.data.totalAmount,
						totalTokens: res.data.totalTokens
					});
				}
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	checkDetails = outletName => {
		const { startDate, endDate } = this.state;
		// console.log({ outletName, startDate, endDate });
		this.props.history.push("/admin/token/report/detail", { outletName, startDate, endDate });
	};

	render() {
		let data = {
			columns: [
				{
					label: i18n.t("Outlet Name"),
					field: "outletName",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of e-token used in all the transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total e-Token Collected")}</span>
						</OverlayTrigger>
					),
					field: "tokenCollected",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of e-token used in washer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Washer e-Token Collected")}</span>
						</OverlayTrigger>
					),
					field: "washerTokens",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of e-token used in dryer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Dryer e-Token Collected")}</span>
						</OverlayTrigger>
					),
					field: "dryerTokens",
					sort: "asc"
				}
			],
			rows: []
		};

		if (this.state.tableData) {
			// console.log(this.state.tableData);
			for (let i = 0; i < this.state.tableData.length; i++) {
				const dataObject = {
					outletName: (
						<button type="button" className="btn btn-link" onClick={e => this.checkDetails(this.state.tableData[i].outletName)}>
							{this.state.tableData[i].outletName}
						</button>
					),
					tokenCollected: this.state.tableData[i].tokenCollected,
					washerTokens: this.state.tableData[i].washerTokens,
					dryerTokens: this.state.tableData[i].dryerTokens
				};
				data.rows.push(dataObject);
			}
		}

		const csvData = {
			headers: [
				{ label: "Outlet Name", key: "outletName" },
				{ label: "Total e-Token Collected", key: "tokenCollected" },
				{ label: "Washer e-Token Collected", key: "washerTokens" },
				{ label: "Dryer e-Token Collected", key: "dryerTokens" }
			],
			data: this.state.tableData
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header d-flex justify-content-between">
								<h5 className="card-title">{i18n.t("Token Report")}</h5>

								<Link className="btn btn-primary" to="/admin/token/report/purchasehistory">
									{i18n.t("Purchase History")}
								</Link>
							</div>

							<div className="card-body">
								<div className="mb-5">
									<h6>{i18n.t("Filter")}</h6>
									<div>
										<label htmlFor="outletId">{i18n.t("Select Outlet")}:</label>
										<select name="outletId" id="outletId" className="browser-default form-select" value={this.state.outletId} onChange={this.handleChange}>
											<option disabled value="">
												{i18n.t("All")}
											</option>
											{this.props.admin?.admin?.outlets
												?.filter(x => x.outlet_name !== "token")
												.map(outlet => (
													<option key={outlet.id} value={outlet.id}>
														{outlet.outlet_fullname} ({outlet.outlet_name})
													</option>
												))}
										</select>
									</div>

									<div>
										<label htmlFor="date">{i18n.t("Select Transaction Date")}: </label>
										<DatePicker value={`${this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() : ""} - ${this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} />
									</div>

									<div className="d-flex">
										<button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">
											{i18n.t("Filter")}
										</button>
										<button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">
											{i18n.t("Reset")}
										</button>
									</div>
								</div>

								{this.state.loading ? (
									<Loading />
								) : (
									<div>
										<div>
											<p>
												{i18n.t("Most Bought e-Token Package")} :{" "}
												<strong>
													[ {currency(this.props.admin?.admin?.country)}{this.state.topChoice ? this.state.topChoice.price.toFixed(2) : 0} {i18n.t("for")} {this.state.topChoice ? this.state.topChoice.token : 0} {i18n.t("Tokens")} ]
												</strong>
											</p>
											<p>
												{i18n.t("Total Earned from users buying e-Tokens")}: <strong>{currency(this.props.admin?.admin?.country)}{this.state.totalAmount.toFixed(2)}</strong>
											</p>
											<p>
												{i18n.t("Total e-Tokens Bought from you")}: <strong>{this.state.totalTokens} {i18n.t("Tokens")}</strong>
											</p>
										</div>
										<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
										<div className="d-grid gap-2 d-md-flex justify-content-md-end">
											<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`token_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
												{i18n.t("Download Report")}
											</CSVLink>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(TokenReport);
