import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import RewardHistory from '../../admin/components/RewardHistory';

const UserCompensationHistory = ({ client, token }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false)

    useEffect(() => {
        if (!client || !client.user_reward) history.push("/client");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={e => setToggled(!toggled)} />
            <div className="admin-page">
                <Topbar handleToggle={e => setToggled(!toggled)} />
                <div className="admin-content">
                    <RewardHistory
                        operatorId={client?.admin.map(admin => admin.id)}
                        authtoken={token}
                        stamp={client?.stamp}
                        etoken={client?.token}
                        point={client?.loyalty_point}
                        role="client"
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(UserCompensationHistory);
