import React, { Component } from "react";
import { connect } from "react-redux";
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { currency, dateFormat, timeFormat } from "../../../dynamicController";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class EPaymentReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			data: null,
			loading: true,
			startDate: "",
			endDate: "",
		};
	}

	componentDidMount() {
		if (!this.props.distributor || !this.props.distributor.epayment_report) {
			return this.props.history.push("/distributor");
		} else {
			let sendThis = {
				operatorId: this.props.distributor.admin.map(admin => admin.id)
			}
			axios
				.post("/api/admin/report/ePaymentReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
				.then(res => {
					this.setState({
						...this.state,
						loading: false,
						data: res.data.data
					});
				})
				.catch(err => {
					this.setState({
						...this.state,
						loading: false
					});
					mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
				});
		}
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleDate = dates => {
		const [start, end] = dates;

		this.setState({
			...this.state,
			startDate: start,
			endDate: end
		});
	};

	handleFilter = e => {
		const { startDate, endDate } = this.state;
		let sendThis = {
			operatorId: this.props.distributor.admin.map(admin => admin.id)
		}

		if (startDate && endDate) {
			sendThis.startDate = new Date(startDate);
			sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
		}
		this.setState({
			...this.state,
			loading: true
		});
		axios
			.post("/api/admin/report/ePaymentReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	handleReset = e => {
		this.setState({
			...this.state,
			startDate: "",
			endDate: "",
			loading: true,
			data: []
		});
		let sendThis = {
			operatorId: this.props.distributor.admin.map(admin => admin.id)
		}

		axios
			.post("/api/admin/report/ePaymentReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	render() {
		let data = {
			columns: [
				{
					label: i18n.t("Transaction Date"),
					field: "date",
					sort: "asc"
				},
				{
					label: i18n.t("User Name"),
					field: "name",
					sort: "asc"
				},
				{
					label: i18n.t("Phone Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Email Address"),
					field: "email",
					sort: "asc"
				},
				{
					label: i18n.t("Member ID"),
					field: "member_id",
					sort: "asc"
				},
				{
					label: i18n.t("Transaction ID"),
					field: "transaction_id",
					sort: "asc"
				},
				{
					label: i18n.t("Method"),
					field: "method",
					sort: "asc"
				},
				{
					label: `${i18n.t("Total Amount")} (${currency(this.props.distributor.country)})`,
					field: "total_amount",
					sort: "asc"
				},
				{
					label: i18n.t("Type"),
					field: "type",
					sort: "asc"
				},
			],
			rows: this.state.data ?? []
		};

		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				let data = {
					date: this.state.data[i].date,
					name: this.state.data[i].name,
					number: `=""${this.state.data[i].number}""`,
					email: this.state.data[i].email,
					member_id: this.state.data[i].member_id,
					transaction_id: this.state.data[i].transaction_id,
					method: this.state.data[i].method,
					total_amount: this.state.data[i].total_amount,
					type: this.state.data[i].type,
				}
				csv.push(data);
			}
		}

		const csvData = {
			headers: [
				{ label: "Transaction Date", key: "date" },
				{ label: "User Name", key: "name" },
				{ label: "Phone Number", key: "number" },
				{ label: "Email Address", key: "email" },
				{ label: "Member ID", key: "member_id" },
				{ label: "Transaction ID", key: "transaction_id" },
				{ label: "Method", key: "method" },
				{ label: `Total Amount (${currency(this.props.distributor.country)})`, key: "total_amount" },
				{ label: "Type", key: "type" },
			],
			data: csv
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">{i18n.t("E-Payment Report")}</h5>
							</div>

							<div className="card-body">
								<div className="mb-5">
									<h6>{i18n.t("Filter")}</h6>
									<div>
										<label htmlFor="date">{i18n.t("Select Transaction Date")}: </label>
										<DatePicker value={`${this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() : ""} - ${this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} />
									</div>

									<div className="d-flex">
										<button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">
											{i18n.t("Filter")}
										</button>
										<button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">
											{i18n.t("Reset")}
										</button>
									</div>
								</div>
								<div className="text-center mt-5">
									{
										this.state.loading ? <Loading /> : (
											<div>
												<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
												<div className="d-grid gap-2 d-md-flex justify-content-md-end">
													<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`epayment_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
														{i18n.t("Download Report")}
													</CSVLink>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		distributor: state.distributor.distributor,
		token: state.distributor.token
	};
};

export default connect(mapStateToProps, null)(EPaymentReport);
