import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import Loading from '../../admin/components/Loading';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const StartTask = ({ token }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [machineList, setMachineList] = useState([]);
    const [toggled, setToggled] = useState(false);
    const [machine, setMachine] = useState("");
    const [loading, setLoading] = useState(false);
    const [task, setTask] = useState(null);

    useEffect(() => {
        if (!location.state) {
            history.push("/staff")
        } else {
            setTask(location.state.task)
            if (location.state.task.name === "Wash" || location.state.task.name === "Dry") {
                setLoading(true)
                axios.post('/api/staff/getMachineList', { outletId: location.state.task.outletId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                    .then(res => {
                        const filteredMachines = res.data.data.machineData.filter(machine => machine.running === false && machine.online && machine.type.includes(location.state.task.name) && parseInt(machine.capacity) === parseInt(location.state.task.capacity));
                        setMachineList(filteredMachines);
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                        mySwal.fire(t("Error"), t(err.response.data.error), "error");
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleComplete = () => {
        setLoading(true)
        axios.post('/api/staff/completeTask', { taskId: task.id, machine }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                setLoading(false)
                mySwal.fire(t("Sucess"), "", "success");
                history.push('/staff/dashboard')
            })
            .catch(err => {
                setLoading(false)
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    const handleToggle = () => {
        setToggled(!toggled);
    }


    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <div className="mb-3 d-flex" onClick={e => history.push('/staff/dashboard')}>
                        <i className="bi bi-chevron-left"></i>
                        <p className="ps-2">{t("Back")}</p>
                    </div>
                    {
                        task ? (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{t(task.name)}</h5>
                                </div>

                                <div className="card-body">
                                    <p>{t("Pack Number")}: {task.pack_number}</p>
                                    <p>{t("Order Date")}: {task.date}</p>
                                    <p>{t("Outlet")}: {task.outlet_fullname} ({task.outlet_name})</p>
                                    <p>{t("Size")}: {task.size}</p>
                                    {task.capacity ? <p>{t("Capacity")}: {task.capacity}</p> : null}
                                    {task.mode ? <p>{t("Mode")}: {task.mode}</p> : null}
                                    {task.machine_duration ? <p>{t("Duration")}: {task.machine_duration} {t("min")}</p> : null}
                                    {task.machine ? <p>{t("Machine")}: {task.machine}</p> : null}

                                    {
                                        task.name === "Wash" || task.name === "Dry" ? (
                                            <div>
                                                <p className='pt-3'>{t("Select Machine")}:</p>
                                                <select
                                                    className="form-control mt-3 font-weight-bold"
                                                    value={machine}
                                                    onChange={e => setMachine(e.target.value)}
                                                >
                                                    <option value="" disabled>{t("Select Machine")}</option>
                                                    {machineList.map(machine => (
                                                        <option key={machine.machineNo} value={machine.machineNo}>
                                                            {machine.name} ({machine.capacity})
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        ) : null
                                    }

                                    {
                                        loading ? (
                                            <Loading />
                                        ) : (
                                            <div>
                                                <button className="btn btn-primary form-control mt-5 font-weight-bold" onClick={() => handleComplete()}>
                                                    {task.name === "Wash" || task.name === "Dry" ? t("Start Machine") : t("Completed")}
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        token: state.staff.token
    };
};

export default connect(mapStateToProps, null)(StartTask);
