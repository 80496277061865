import { useEffect } from 'react';
import { connect } from 'react-redux';
import ClientLogin from './pages/Login';
import ClientDashboard from './pages/ClientDashboard';
import { checkToken, clientTokenLogin, saveClientRedirectUrl, saveClientUserType } from "../../redux/actions/clientActions";
import { changeLanguage } from '../../redux/actions/userActions';
import queryString from 'query-string';

const Client = ({ isAuthenticated, checkToken, location, tokenLogin, saveClientRedirectUrl, saveClientUserType, changeLanguage }) => {

    useEffect(() => {
        const { token, redirectUrl, userType, language } = queryString.parse(location.search);
        if (redirectUrl) {
            saveClientRedirectUrl(redirectUrl)
        }
        if (userType) {
            saveClientUserType(userType)
        }
        if (language) {
            changeLanguage(language)
        }
        if (token) {
            tokenLogin({ token });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Loyalty Login',
                'brand': process.env.REACT_APP_STORETYPE,
                'role': 'client'
            })
        } else {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {
                isAuthenticated
                    ? <ClientDashboard />
                    : <ClientLogin />
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.client.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        tokenLogin: token => dispatch(clientTokenLogin(token)),
        saveClientRedirectUrl: url => dispatch(saveClientRedirectUrl(url)),
        saveClientUserType: data => dispatch(saveClientUserType(data)),
        changeLanguage: (language) => dispatch(changeLanguage(language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Client);