import { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Modal } from "react-bootstrap";

import { checkToken } from '../../../redux/actions/kioskActions';
import { decrypt, logoController } from '../../../dynamicController';

import Payment from "./Payment";

import washerImg from "../../../assets/images/machines/paymentWasher.png";
import dryerImg from "../../../assets/images/machines/paymentDryer.png";
import vendingImg from "../../../assets/images/machines/vendingMachine.png";

const mySwal = withReactContent(Swal);

const Dashboard = ({ data, theme, themeLogo, checkToken }) => {

    const [loading, setLoading] = useState(false);
    const [outlet, setOutlet] = useState(null);
    const [machine, setMachine] = useState(null);
    const [user, setUser] = useState(null);
    const [loginModal, setLoginModal] = useState(false);
    const [number, setNumber] = useState("");
    const [password, setPassword] = useState("");

    const { t } = useTranslation();

    const selectMachine = (machineNo) => {
        setLoading(true)
        setOutlet(null)
        setMachine(null)
        setUser(null)
        checkToken()
        axios.post("/api/user/getMachine", {
            machineNo,
            outletCode: data.outletCode,
            operatorCode: data.opId,
            operatorId: data.operatorCode,
        })
            .then((res) => {
                const response = decrypt(res.data.data);
                if (response.outletStatus !== "online") {
                    mySwal.fire(t("Outlet is currently offline, please try again later"), "", "info");
                } else if (process.env.REACT_APP_STORETYPE !== "cuci" && response.block) {
                    mySwal.fire(t("Machine is Out of Service"), "", "info");
                } else {
                    setMachine(response)
                    setOutlet({
                        machineNo,
                        outletCode: data.outletCode,
                        operatorCode: data.opId
                    })
                    setLoginModal(true)
                }
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    }

    const handleSubmit = e => {
        e.preventDefault();

        setLoading(true)
        setNumber("")
        setPassword("")
        axios.post("/api/user/auth/login", {
            number,
            password,
            operatorId: data.operatorCode
        })
            .then((res) => {
                setUser(res.data.data.user)
                setLoginModal(false)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                if (err.response.data.error === "Click here to register") {
                    mySwal.fire(t("Error"), t("User not found"), "error");
                } else {
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                }
            });
    }

    const userLogout = () => {
        setUser(null)
        setMachine(null)
        setOutlet(null)
    }

    return (
        <div style={{ display: "flex", backgroundColor: "#5384a6" }}>
            <div style={{ minHeight: "100vh", width: "50%" }}>
                <div className="d-flex justify-content-around flex-wrap" style={{ padding: "5%" }}>
                    {
                        data?.machineData.map((data, i) => {
                            return (
                                <div key={i} style={{ backgroundColor: "white", borderRadius: "12px", width: "30%", textAlign: "center", marginBottom: "3%" }} onClick={() => selectMachine(data.machineNo)}>
                                    <div style={{ paddingTop: "5%", paddingBottom: "5%", textAlign: "center" }}>
                                        <img src={data.type === "Washer" ? washerImg : data.type === "Dryer" ? dryerImg : vendingImg} alt="" className="img-fluid" style={{ maxWidth: "50px" }} />
                                    </div>
                                    {data.name ? <p className='m-0 p-0'><b>{data.name}</b></p> : null}
                                    {data.type ? <p className='m-0 p-0'><small>{t(data.type)} {data.capacity ? <small>({data.capacity})</small> : null}</small></p> : null}
                                    <div className="d-flex justify-content-center" style={{ marginBottom: "5%", marginTop: "3%" }}>
                                        <div style={{ backgroundColor: data.online ? "#8be78b" : "#d33", padding: "2%", marginRight: "3%", borderRadius: "10px" }}><strong>{data.online ? t("Online") : t("Offline")}</strong></div>
                                        <div style={{ backgroundColor: data.running ? "#d33" : "#8be78b", padding: "2%", borderRadius: "10px" }}><strong>{data.running ? t("Running") : t("Vacant")}</strong></div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div style={{ minHeight: "100vh", width: "50%", backgroundColor: "white" }}>
                {
                    loading ? (
                        <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : !machine ? (
                        <div style={{ padding: "5%" }}>
                            <div style={{ width: "30%", margin: "auto" }}>
                                <img src={logoController(themeLogo)} alt="main logo" className="img-fluid" />
                            </div>
                            <h4 className='pt-3 text-center'>{t("Welcome to")} {data.outlet_fullname}</h4>
                            <p>{t("Instruction")}</p>
                            <ol>
                                <li>{t("Please select the machine")}</li>
                                <li>{t("Login your account")}</li>
                                <li>{t("Select the setting and make the payment")}</li>
                            </ol>
                        </div>
                    ) : (
                        <div>
                            <Payment
                                machine={machine}
                                outlet={outlet}
                                outletFullname={data.outlet_fullname}
                                user={user}
                                userLogout={userLogout}
                                theme={theme}
                            />
                        </div>
                    )
                }
            </div>
            <Modal
                show={loginModal}
                onHide={(e) => { setLoginModal(false); userLogout(); }}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Login")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={theme}>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input type="text" id="number" name="number" placeholder={t("Phone Number / Email")} className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <input type="password" id="password" name="password" placeholder={t("Password")} className="form-control browser-default" value={password} onChange={e => setPassword(e.target.value)} required />
                            </div>

                            {
                                loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <input id="login" type="submit" value={t("LOGIN")} className="big-button form-control btn mt-3" />
                                )
                            }
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        data: state.kiosk.data,
        theme: state.theme.theme,
        themeLogo: state.theme.logo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken())
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);