import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../pages/components/Sidebar";
import Topbar from "../pages/components/Topbar";
import UserCompensation from "../../admin/components/UserCompensation";

const Giveaway = ({ distributor, token }) => {

	const history = useHistory();

	const [toggled, setToggled] = useState(false)

	useEffect(() => {
		if (!distributor || !distributor.user_reward) history.push("/distributor");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="admin-page-container">
			<Sidebar toggled={toggled} setToggled={e => setToggled(!toggled)} />
			<div className="admin-page">
				<Topbar handleToggle={e => setToggled(!toggled)} />
				<div className="admin-content">
					<UserCompensation
						operatorId={distributor?.admin.map(admin => admin.id)}
						authtoken={token}
						stamp={distributor?.stamp}
						etoken={distributor?.token}
						point={distributor?.loyalty_point}
						id={distributor?.id}
						role="distributor"
						country={distributor?.country}
						rewardHistoryPath="/distributor/giveaway/history"
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		distributor: state.distributor.distributor,
		token: state.distributor.token
	};
};

export default connect(mapStateToProps, null)(Giveaway);
