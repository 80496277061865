import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from './components/Sidebar';
import Topbar from "./components/Topbar";
import ReferralRewardHistoryReport from '../../admin/components/ReferralRewardHistoryReport';

const DistributorReferralRewardHistoryReport = ({ distributor, token }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false)

    useEffect(() => {
        if (!distributor || !distributor.referral_reward_history_report) history.push("/distributor");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={e => setToggled(!toggled)} />
            <div className="admin-page">
                <Topbar handleToggle={e => setToggled(!toggled)} />
                <div className="admin-content">
                    <ReferralRewardHistoryReport
                        token={token}
                        role="distributor"
                        stamp={distributor?.stamp}
                        etoken={distributor?.token}
                        point={distributor?.loyalty_point}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(DistributorReferralRewardHistoryReport);