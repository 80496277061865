import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import Loading from '../../admin/components/Loading';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const Dashboard = ({ token, history }) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState('Pending');
    const [allPackNumber, setAllPackNumber] = useState([]);
    const [toggled, setToggled] = useState(false);
    const [allTask, setAllTask] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTasks(status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const fetchTasks = (status) => {
        setLoading(true)
        axios.post('/api/staff/getTask', { status }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                let allNumber = []
                for (let i = 0; i < res.data.data.length; i++) {
                    if (allNumber.filter(num => num.pack_number === res.data.data[i].pack_number).length <= 0) {
                        allNumber.push({
                            status: res.data.data[i].status === "Completed" ? "In Progress" : "Pending",
                            pack_number: res.data.data[i].pack_number
                        })
                    }
                }

                setAllPackNumber(allNumber);
                setAllTask(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const selectPack = (packNum) => {
        const thisPackTask = allTask.filter(t => t.pack_number === packNum)
        history.push('/staff/task/details', { taskList: thisPackTask })
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <h3 className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>{t("Drop And Go Portal")}</h3>

                    <select
                        className="form-control mt-5 font-weight-bold"
                        value={status}
                        onChange={e => setStatus(e.target.value)}
                    >
                        <option value="Pending">{t("Available Task")}</option>
                        <option value="Completed">{t("Completed Task")}</option>
                    </select>

                    <div className='d-flex flex-wrap justify-content-around'>
                        {
                            loading
                                ? <Loading />
                                : (
                                    allPackNumber.map((number, i) => {
                                        return (
                                            <div key={i}
                                                style={{ padding: '20px', border: status === "Completed" ? '2px solid green' : number.status === "In Progress" ? '2px solid #FF3C00' : '2px solid grey', borderRadius: '10px', marginTop: '30px', background: "white", color: status === "Completed" ? "green" : number.status === "In Progress" ? "#FF3C00" : "black" }}
                                                onClick={() => selectPack(number.pack_number)}
                                            >
                                                <h4>{t("Pack Number")}: {number.pack_number}</h4>
                                            </div>
                                        )
                                    })
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        token: state.staff.token
    };
};

export default connect(mapStateToProps, null)(Dashboard); 
