import { useState } from "react";
import { useTranslation } from "react-i18next";

import paymentWasher from "../../../assets/images/machines/paymentWasher.png";
import paymentDryer from "../../../assets/images/machines/paymentDryer.png";
import vendingMachine from "../../../assets/images/machines/vendingMachine.png";

import cpPlusLogo from "../../../assets/images/logo/Logo_Cleanproplus-white.png";
import cpPlusWasher from "../../../assets/images/machines/icn-washer.png";
import cpPlusDryer from "../../../assets/images/machines/icn-dryer.png";
import locationIcon from "../../../assets/images/icons/location.png";

import washupWasher from "../../../assets/images/machines/washup-washer.png";
import washupDryer from "../../../assets/images/machines/washup-dryer.png";

import VendingPayment from "./payment/VendingPayment";
import WasherPayment from "./payment/WasherPayment";
import DryerPayment from "./payment/DryerPayment";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Payment = ({ machine, outletFullname, user, userLogout, outlet, theme }) => {

    const { t } = useTranslation();

    const [duration, setDuration] = useState(0);

    return (
        <div id="payment-page">
            <div id="user-header">
                {STORETYPE === "cleanpro" ? (
                    <div>
                        <div className="d-flex justify-content-between pt-2">
                            <img className="plus-logo" src={cpPlusLogo} alt="" />
                            <h5 className="mt-2">
                                <i
                                    onClick={(e) => userLogout()}
                                    className="bi bi-chevron-left me-3"
                                ></i>
                            </h5>
                        </div>
                        <div className="d-flex pb-3">
                            <div>
                                <img src={locationIcon} alt="" />
                            </div>
                            <p className="ml-1" style={{ fontSize: "11px", marginTop: "auto" }}>{outletFullname}</p>
                        </div>
                    </div>
                ) : (
                    <h5>
                        <i
                            onClick={(e) => userLogout()}
                            className="bi bi-chevron-left me-3"
                        ></i>
                        <strong>
                            {t(`${machine.type} Payment`)}
                        </strong>
                    </h5>
                )}

                <div id="machine-info-container">
                    <div id="machine-image-container">
                        {STORETYPE === "cleanpro" && (
                            <h3>
                                {machine.type === "Washer" ? t("Wash")
                                    : machine.type === "Dryer" ? t("Dry")
                                        : machine.type === "Vending Machine" ? t("Vending")
                                            : machine.type}
                            </h3>
                        )}
                        {STORETYPE === "washup" && (
                            <h3>
                                {machine.type === "Vending Machine" ? t("Vending") : t(machine.type)}
                            </h3>
                        )}
                        {
                            machine.type === "Washer" ? (
                                <img src={STORETYPE === "cleanpro" ? cpPlusWasher : STORETYPE === "washup" ? washupWasher : paymentWasher} alt="washer" className="img-fluid" />
                            ) : machine.type === "Dryer" ? (
                                <img src={STORETYPE === "cleanpro" ? cpPlusDryer : STORETYPE === "washup" ? washupDryer : paymentDryer} alt="dryer" className="img-fluid" />
                            ) : (
                                <img src={vendingMachine} alt="dryer" className="img-fluid" />
                            )
                        }
                    </div>

                    <div id="machine-info">
                        {
                            machine.type !== "Washer" && machine.type !== "Dryer" ? (
                                <>
                                    <h5 className="m-0 p-0">{machine.name}</h5>
                                    <h6 className="m-0 p-0">{t(machine.type)}</h6>
                                    <div id="badge-container" className="mt-2">
                                        <div
                                            className="status-badge"
                                            style={
                                                machine.online && machine.outletStatus === "online"
                                                    ? { backgroundColor: STORETYPE === "cuci" ? "#ffd109" : STORETYPE === "washup" ? "#CCB300" : "#8be78b" }
                                                    : { backgroundColor: "#d33" }
                                            }
                                        >
                                            <strong>{machine.online && machine.outletStatus ? t("Online") : t("Offline")}</strong>
                                        </div>

                                        <div
                                            className="status-badge"
                                            style={machine.running ? { backgroundColor: "#d33" } : null}
                                        >
                                            <strong>
                                                {machine.running ? t("Running") : t("Vacant")}
                                            </strong>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h5 className="m-0 p-0">
                                        {STORETYPE !== "cleanpro" && STORETYPE !== "washup" && `${t(machine.type)} -`} {machine.name}
                                    </h5>
                                    <h6 className="m-0 p-0">
                                        {STORETYPE !== "cleanpro" && STORETYPE !== "washup" && machine.capacity}
                                    </h6>
                                    {
                                        STORETYPE === "cleanpro" || STORETYPE === "washup" ? (
                                            <div className="mt-2" id="badge-container">
                                                <div>
                                                    <p className="m-0" style={{ fontSize: "12px", color: "#525252" }}>{t("Capacity")}</p>
                                                    <strong style={{ fontSize: "16px" }}>{machine.capacity}</strong>
                                                </div>
                                                <div>
                                                    <p className="m-0" style={{ fontSize: "12px", color: "#525252" }}>{t("Duration")}</p>
                                                    <strong style={{ fontSize: "16px" }}>
                                                        {duration} {t("min")}
                                                    </strong>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    {STORETYPE !== "cleanpro" ? (
                                        <div id="badge-container" className="mt-2">
                                            <div
                                                className="status-badge"
                                                style={
                                                    machine.online && machine.outletStatus === "online"
                                                        ? {
                                                            backgroundColor:
                                                                STORETYPE === "cuci"
                                                                    ? "#ffd109"
                                                                    : STORETYPE === "washup"
                                                                        ? "#CCB300"
                                                                        : "#8be78b",
                                                        }
                                                        : { backgroundColor: "#d33" }
                                                }
                                            >
                                                <strong>
                                                    {
                                                        machine.online && machine.outletStatus === "online"
                                                            ? t("Online")
                                                            : t("Offline")
                                                    }
                                                </strong>
                                            </div>

                                            <div
                                                className="status-badge"
                                                style={
                                                    machine.running
                                                        ? { backgroundColor: "#d33" }
                                                        : null
                                                }
                                            >
                                                <strong>
                                                    {machine.running ? t("Running") : t("Vacant")}
                                                </strong>
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>

            <div id="user-body">
                {
                    machine.type === "Washer" ? (
                        <WasherPayment
                            setDuration={setDuration}
                            machine={machine}
                            outlet={outlet}
                            user={user}
                            theme={theme}
                            userLogout={userLogout}
                        />
                    ) : machine.type === "Dryer" ? (
                        <DryerPayment
                            setDuration={setDuration}
                            machine={machine}
                            outlet={outlet}
                            user={user}
                            theme={theme}
                            userLogout={userLogout}
                        />
                    ) : (
                        <VendingPayment
                            machine={machine}
                            outlet={outlet}
                            user={user}
                            theme={theme}
                            userLogout={userLogout}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default Payment