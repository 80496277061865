import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { decrypt } from "../../../../dynamicController";
import { checkToken } from "../../../../redux/actions/userActions";
import { saveCreds, scannedCode } from "../../../../redux/actions/paymentActions";

const mySwal = withReactContent(Swal);

const OutletSelection = ({ operatorId, user, checkToken, scanned, saveCredentials }) => {

    const { t } = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [outletId, setOutletId] = useState("");
    const [outletList, setOutletList] = useState([]);

    useEffect(() => {
        if (!user) {
            history.push("/")
        }

        axios
            .post("/api/user/getOutlet", { operatorId })
            .then(res => {
                setOutletList(res.data.data.outlets)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!outletId) {
            mySwal.fire(t("Error"), t("Please fill in all the required fields before submit."), "error");
        } else {
            setLoading(true)
            axios
                .post("/api/user/encrypt", { outletId })
                .then(res => {
                    let sendThis = {
                        opId: res.data.operatorCode,
                        outletCode: res.data.outletCode,
                        operatorId
                    }

                    if (user) {
                        sendThis.migrate_id = user.migrate_id
                    }

                    axios
                        .post("/api/user/getDngPricing", sendThis)
                        .then(resp => {
                            const response = decrypt(resp.data.data)
                            scanned(response)
                            saveCredentials({ operatorCode: res.data.operatorCode, outletCode: res.data.outletCode })
                            if (user && user.migrate_id) {
                                checkToken()
                            }
                            history.push("/user/dng/payment")
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error");
                        })
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                })
        }
    }

    return (
        <div id="setting-page">
            <div id="setting-main" className="dng-outlet-selection">
                <div id="user-header">
                    <h5>
                        <i
                            onClick={(e) => history.goBack()}
                            className="bi bi-chevron-left me-3"
                        ></i>{" "}
                        <strong>{t("Drop n Go Service")}</strong>
                    </h5>
                </div>

                <div id="user-body" style={{ paddingTop: "30px" }}>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group px-5">
                            <select
                                name="outletId"
                                id="outletId"
                                className="form-select"
                                value={outletId}
                                onChange={e => setOutletId(e.target.value)}
                                required
                            >
                                <option value="" disabled>
                                    {t("Select an outlet")}
                                </option>
                                {outletList.map((outlet) => (
                                    <option key={outlet.id} value={outlet.id}>
                                        {outlet.outlet_fullname}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="px-5">
                            {
                                loading ? (
                                    <div className="text-center">
                                        <div
                                            className={process.env.REACT_APP_STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"}
                                            role="status"
                                        >
                                            <span className="sr-only">Loading ...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <button
                                        id="submit-dng-outlet"
                                        type="submit"
                                        className="btn big-button form-control mt-3"
                                    >
                                        {t("SUBMIT")}
                                    </button>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        operatorId: state.user.operatorId,
        user: state.user.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        scanned: data => dispatch(scannedCode(data)),
        saveCredentials: data => dispatch(saveCreds(data)),
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutletSelection);