import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { logo1Controller } from "../../../../dynamicController";

const Sidebar = ({ toggled, setToggled, themeLogo, client }) => {

	const { t } = useTranslation();

	const handleClick = (feature) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': feature,
			'user_id': client ? client.username : null,
			'brand': process.env.REACT_APP_STORETYPE,
			'role': 'client'
		})
	}

	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<Link to="/client/dashboard">
					<img src={logo1Controller(themeLogo)} alt="logo" className="img-fluid" />
				</Link>
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-house-door-fill"></i>}>
						{t("Dashboard")} <Link to="/client/dashboard" onClick={() => handleClick("Dashboard")} />
					</MenuItem>

					{
						client.store_performance || client.user_performance || client.voucher_redemption || client.token_report || client.sms_report || client.discount_code_report || client.voucher_code_report || client.floating_token_report || client.epayment_report || client.floating_point_report || client.referral_reward_history_report ? (
							<SubMenu title={t("Report")} icon={<i className="bi bi-file-bar-graph-fill"></i>}>
								{
									client.store_performance ? (
										<MenuItem>
											{t("Store Performance")} <Link to="/client/outlet/performance" onClick={() => handleClick("Store Performance")} />
										</MenuItem>
									) : <></>
								}
								{
									client.user_performance ? (
										<MenuItem>
											{t("User Performance")} <Link to="/client/user/performance" onClick={() => handleClick("User Performance")} />
										</MenuItem>
									) : <></>
								}
								{
									client.voucher_redemption ? (
										<MenuItem>
											{t("Voucher Redemption")} <Link to="/client/voucher/redemption" onClick={() => handleClick("Voucher Redemption")} />
										</MenuItem>
									) : <></>
								}
								{
									client.token_report ? (
										<MenuItem>
											{t("Token Report")} <Link to="/client/token/report" onClick={() => handleClick("Token Report")} />
										</MenuItem>
									) : <></>
								}
								{
									client.floating_token_report ? (
										<MenuItem>
											{t("Floating Token Report")} <Link to="/client/floatingToken/report" onClick={() => handleClick("Floating Token Report")} />
										</MenuItem>
									) : <></>
								}
								{
									client.floating_point_report ? (
										<MenuItem>
											{t("Floating Point Report")} <Link to="/client/floatingPoint/report" onClick={() => handleClick("Floating Point Report")} />
										</MenuItem>
									) : <></>
								}
								{
									process.env.REACT_APP_STORETYPE !== "cuci" && client.sms_report ? (
										<MenuItem>
											{t("SMS Report")} <Link to="/client/sms/report" onClick={() => handleClick("SMS Report")} />
										</MenuItem>
									) : <></>
								}
								{
									client.discount_code_report ? (
										<MenuItem>
											{t("Discount Code Report")} <Link to="/client/discountCode/report" onClick={() => handleClick("Discount Code Report")} />
										</MenuItem>
									) : <></>
								}
								{
									process.env.REACT_APP_STORETYPE === "cleanpro" && client.voucher_code_report ? (
										<MenuItem>
											{t("Voucher Code Report")} <Link to="/client/voucherCode/report" onClick={() => handleClick("Voucher Code Report")} />
										</MenuItem>
									) : <></>
								}
								{
									client.epayment_report ? (
										<MenuItem>
											{t("E-Payment Report")} <Link to="/client/epayment/report" onClick={() => handleClick("E-Payment Report")} />
										</MenuItem>
									) : <></>
								}
								{
									client.referral_reward_history_report ? (
										<MenuItem>
											{t("Referral Reward History Report")} <Link to="/client/referralReward/report" onClick={() => handleClick("Referral Reward History Report")} />
										</MenuItem>
									) : <></>
								}
							</SubMenu>
						) : <></>
					}

					{
						client.user_detail ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-person-lines-fill"></i>}>
								{t("User Details")} <Link to="/client/user/details" onClick={() => handleClick("User Details")} />
							</MenuItem>
						) : <></>
					}

					{
						client.user_reward ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gift-fill"></i>}>
								{t("User Compensation")} <Link to="/client/giveaway" onClick={() => handleClick("User Compensation")} />
							</MenuItem>
						) : <></>
					}

					{
						client.voucher_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gear-fill"></i>}>
								{t("Voucher Settings")} <Link to="/client/voucher/setting" onClick={() => handleClick("Voucher Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						client.token_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-coin"></i>}>
								{t("Token Settings")} <Link to="/client/token/setting" onClick={() => handleClick("Token Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						client.referral_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-people-fill"></i>}>
								{t("Referral Settings")} <Link to="/client/referral" onClick={() => handleClick("Referral Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						client.discount_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-percent"></i>}>
								{t("Discount Code")} <Link to="/client/discountCode" onClick={() => handleClick("Discount Code")} />
							</MenuItem>
						) : <></>
					}

					{
						process.env.REACT_APP_STORETYPE === "cleanpro" && client.voucher_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-upc"></i>}>
								{t("Voucher Code")} <Link to="/client/voucherCode" onClick={() => handleClick("Voucher Code")} />
							</MenuItem>
						) : <></>
					}

					{process.env.REACT_APP_STORETYPE !== "cuci" && process.env.REACT_APP_STORETYPE !== "cleanpro" && client.customize_color ? (
						<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-palette-fill"></i>}>
							{t("Customize Color")} <Link to="/client/setting/customize" onClick={() => handleClick("Customize Color")} />
						</MenuItem>
					) : (
						<></>
					)}

					{
						client.faq_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-question-circle-fill"></i>}>
								{t("FAQs")} <Link to="/client/faq" onClick={() => handleClick("FAQs")} />
							</MenuItem>
						) : <></>
					}

					{
						client.news_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-newspaper"></i>}>
								{t("News")} <Link to="/client/news" onClick={() => handleClick("News")} />
							</MenuItem>
						) : <></>
					}

				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		client: state.client.client
	};
};

export default connect(mapStateToProps, null)(Sidebar);