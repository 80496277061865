import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import UserCompensation from "../../admin/components/UserCompensation";

const Giveaway = ({ client, token }) => {

	const history = useHistory();

	const [toggled, setToggled] = useState(false)

	useEffect(() => {
		if (!client || !client.user_reward) history.push("/client");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="admin-page-container">
			<Sidebar toggled={toggled} setToggled={e => setToggled(!toggled)} />
			<div className="admin-page">
				<Topbar handleToggle={e => setToggled(!toggled)} />
				<div className="admin-content">
					<UserCompensation
						operatorId={client?.admin.map(admin => admin.id)}
						authtoken={token}
						stamp={client?.stamp}
						etoken={client?.token}
						point={client?.loyalty_point}
						id={client?.id}
						role="client"
						rewardHistoryPath="/client/giveaway/history"
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		client: state.client.client,
		token: state.client.token
	};
};

export default connect(mapStateToProps, null)(Giveaway);
