import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { logo1Controller } from "../../../../dynamicController";

const Sidebar = ({ toggled, setToggled, themeLogo, distributor }) => {

	const { t } = useTranslation();

	const handleClick = (feature) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': feature,
			'user_id': distributor ? distributor.username : null,
			'brand': process.env.REACT_APP_STORETYPE,
			'role': 'distributor'
		})
	}

	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<Link to="/distributor/dashboard">
					<img src={logo1Controller(themeLogo)} alt="logo" className="img-fluid" />
				</Link>
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-house-door-fill"></i>}>
						{t("Dashboard")} <Link to="/distributor/dashboard" onClick={() => handleClick("Dashboard")} />
					</MenuItem>

					{
						distributor.store_performance || distributor.user_performance || distributor.voucher_redemption || distributor.token_report || distributor.sms_report || distributor.discount_code_report || distributor.voucher_code_report || distributor.floating_token_report || distributor.epayment_report || distributor.floating_point_report || distributor.referral_reward_history_report ? (
							<SubMenu title={t("Report")} icon={<i className="bi bi-file-bar-graph-fill"></i>}>
								{
									distributor.store_performance ? (
										<MenuItem>
											{t("Store Performance")} <Link to="/distributor/outlet/performance" onClick={() => handleClick("Store Performance")} />
										</MenuItem>
									) : <></>
								}
								{
									distributor.user_performance ? (
										<MenuItem>
											{t("User Performance")} <Link to="/distributor/user/performance" onClick={() => handleClick("User Performance")} />
										</MenuItem>
									) : <></>
								}
								{
									distributor.voucher_redemption ? (
										<MenuItem>
											{t("Voucher Redemption")} <Link to="/distributor/voucher/redemption" onClick={() => handleClick("Voucher Redemption")} />
										</MenuItem>
									) : <></>
								}
								{
									distributor.token_report ? (
										<MenuItem>
											{t("Token Report")} <Link to="/distributor/token/report" onClick={() => handleClick("Token Report")} />
										</MenuItem>
									) : <></>
								}
								{
									distributor.floating_token_report ? (
										<MenuItem>
											{t("Floating Token Report")} <Link to="/distributor/floatingToken/report" onClick={() => handleClick("Floating Token Report")} />
										</MenuItem>
									) : <></>
								}
								{
									distributor.floating_point_report ? (
										<MenuItem>
											{t("Floating Point Report")} <Link to="/distributor/floatingPoint/report" onClick={() => handleClick("Floating Point Report")} />
										</MenuItem>
									) : <></>
								}
								{
									process.env.REACT_APP_STORETYPE !== "cuci" && distributor.sms_report ? (
										<MenuItem>
											{t("SMS Report")} <Link to="/distributor/sms/report" onClick={() => handleClick("SMS Report")} />
										</MenuItem>
									) : <></>
								}
								{
									distributor.discount_code_report ? (
										<MenuItem>
											{t("Discount Code Report")} <Link to="/distributor/discountCode/report" onClick={() => handleClick("Discount Code Report")} />
										</MenuItem>
									) : <></>
								}
								{
									process.env.REACT_APP_STORETYPE === "cleanpro" && distributor.voucher_code_report ? (
										<MenuItem>
											{t("Voucher Code Report")} <Link to="/distributor/voucherCode/report" onClick={() => handleClick("Voucher Code Report")} />
										</MenuItem>
									) : <></>
								}
								{
									distributor.epayment_report ? (
										<MenuItem>
											{t("E-Payment Report")} <Link to="/distributor/epayment/report" onClick={() => handleClick("E-Payment Report")} />
										</MenuItem>
									) : <></>
								}
								{
									distributor.referral_reward_history_report ? (
										<MenuItem>
											{t("Referral Reward History Report")} <Link to="/distributor/referralReward/report" onClick={() => handleClick("Referral Reward History Report")} />
										</MenuItem>
									) : <></>
								}
							</SubMenu>
						) : <></>
					}

					{
						distributor.user_detail ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-person-lines-fill"></i>}>
								{t("User Details")} <Link to="/distributor/user/details" onClick={() => handleClick("User Details")} />
							</MenuItem>
						) : <></>
					}

					{
						distributor.user_reward ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gift-fill"></i>}>
								{t("User Compensation")} <Link to="/distributor/giveaway" onClick={() => handleClick("User Compensation")} />
							</MenuItem>
						) : <></>
					}

					{
						distributor.voucher_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gear-fill"></i>}>
								{t("Voucher Settings")} <Link to="/distributor/voucher/setting" onClick={() => handleClick("Voucher Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						distributor.token_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-coin"></i>}>
								{t("Token Settings")} <Link to="/distributor/token/setting" onClick={() => handleClick("Token Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						distributor.referral_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-people-fill"></i>}>
								{t("Referral Settings")} <Link to="/distributor/referral" onClick={() => handleClick("Referral Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						distributor.discount_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-percent"></i>}>
								{t("Discount Code")} <Link to="/distributor/discountCode" onClick={() => handleClick("Discount Code")} />
							</MenuItem>
						) : <></>
					}

					{
						process.env.REACT_APP_STORETYPE === "cleanpro" && distributor.voucher_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-upc"></i>}>
								{t("Voucher Code")} <Link to="/distributor/voucherCode" onClick={() => handleClick("Voucher Code")} />
							</MenuItem>
						) : <></>
					}

					{process.env.REACT_APP_STORETYPE !== "cuci" && process.env.REACT_APP_STORETYPE !== "cleanpro" && distributor.customize_color ? (
						<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-palette-fill"></i>}>
							{t("Customize Color")} <Link to="/distributor/setting/customize" onClick={() => handleClick("Customize Color")} />
						</MenuItem>
					) : (
						<></>
					)}

					{
						distributor.faq_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-question-circle-fill"></i>}>
								{t("FAQs")} <Link to="/distributor/faq" onClick={() => handleClick("FAQs")} />
							</MenuItem>
						) : <></>
					}

					{
						distributor.news_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-newspaper"></i>}>
								{t("News")} <Link to="/distributor/news" onClick={() => handleClick("News")} />
							</MenuItem>
						) : <></>
					}

					{/* {
						process.env.REACT_APP_STORETYPE === "cleanpro" && distributor.user_migration ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-person-fill"></i>}>
								{t("User Migration")} <Link to="/distributor/migration" />
							</MenuItem>
						) : <></>
					} */}

				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		distributor: state.distributor.distributor
	};
};

export default connect(mapStateToProps, null)(Sidebar);
