import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat, timeFormat } from '../../../dynamicController';
import Loading from './Loading';

const mySwal = withReactContent(Swal);

const FloatingPointReport = ({ operatorId, token, role, operators, distributors }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [distributorId, setDistributorId] = useState("");

    useEffect(() => {
        if (operatorId) {
            setLoading(true)
            axios
                .post("/api/admin/report/userFloatingPointReport", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)

                    axios
                        .post("/api/admin/report/floatingPointReport", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setSummaryData(res.data.data)
                            setLoading(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDate = dates => {
        const [start, end] = dates;

        setStartDate(start)
        setEndDate(end)
    };

    const handleFilter = () => {

        if (role === "client" && !distributorId) {
            mySwal.fire(t("Error"), t("Please select distributor"), "error");
        } else {
            let sendThis = {
                operatorId
            }

            if (role === "client") {
                sendThis.operatorId = operators.filter(admin => admin.distributorId === distributorId).map(admin => admin.id)
            }

            if (startDate && endDate) {
                sendThis.startDate = new Date(startDate);
                sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
            }

            setLoading(true)

            axios
                .post("/api/admin/report/userFloatingPointReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)

                    axios
                        .post("/api/admin/report/floatingPointReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setSummaryData(res.data.data)
                            setLoading(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleReset = () => {
        setStartDate("")
        setEndDate("")
        setData([])
        setSummaryData([])
        setDistributorId("")

        if (operatorId) {
            setLoading(true)

            axios
                .post("/api/admin/report/userFloatingPointReport", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)

                    axios
                        .post("/api/admin/report/floatingPointReport", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setSummaryData(res.data.data)
                            setLoading(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    let userPointDataTable = {
        columns: [
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "member_id",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email Address"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Balance"),
                field: "balance",
                sort: "asc"
            },
            {
                label: t("Gain"),
                field: "gain",
                sort: "asc"
            },
            {
                label: t("Usage"),
                field: "usage",
                sort: "asc"
            },
            {
                label: t("Remaining"),
                field: "remaining",
                sort: "asc"
            },
        ],
        rows: data
    };

    let userPointCsv = [];

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            let dataObject = {
                balance: data[i].balance,
                gain: data[i].gain,
                usage: data[i].usage,
                remaining: data[i].remaining,
                member_id: data[i].member_id,
                name: data[i].name,
                number: `=""${data[i].number}""`,
                email: data[i].email,
            }
            userPointCsv.push(dataObject);
        }
    }

    const userPointCsvData = {
        headers: [
            { label: "User Name", key: "name" },
            { label: "Member ID", key: "member_id" },
            { label: "Phone Number", key: "number" },
            { label: "Email Address", key: "email" },
            { label: "Balance", key: "balance" },
            { label: "Gain", key: "gain" },
            { label: "Usage", key: "usage" },
            { label: "Remaining", key: "remaining" },
        ],
        data: userPointCsv
    };

    let summaryPointDataTable = {
        columns: [
            {
                label: t("Balance"),
                field: "balance",
                sort: "asc"
            },
            {
                label: t("Gain"),
                field: "gain",
                sort: "asc"
            },
            {
                label: t("Usage"),
                field: "usage",
                sort: "asc"
            },
            {
                label: t("Remaining"),
                field: "remaining",
                sort: "asc"
            },
        ],
        rows: summaryData
    };

    let summaryPointCsv = [];

    if (summaryData.length > 0) {
        for (let i = 0; i < summaryData.length; i++) {
            let dataObject = {
                balance: summaryData[i].balance,
                gain: summaryData[i].gain,
                usage: summaryData[i].usage,
                remaining: summaryData[i].remaining,
            }
            summaryPointCsv.push(dataObject);
        }
    }

    const summaryPointCsvData = {
        headers: [
            { label: "Balance", key: "balance" },
            { label: "Gain", key: "gain" },
            { label: "Usage", key: "usage" },
            { label: "Remaining", key: "remaining" },
        ],
        data: summaryPointCsv
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">{t("Floating Point Report")}</h5>
            </div>

            <div className="card-body">
                <div className="mb-5">
                    <h6>{t("Filter")}</h6>
                    {
                        role === "client" ? (
                            <div>
                                <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                                <select id="distributorId" name="distributorId" className="browser-default form-select" value={distributorId} onChange={e => setDistributorId(e.target.value)}>
                                    <option disabled value="">{t("Select Distributor")}</option>
                                    {
                                        distributors.map(distributor => <option key={distributor.id} value={distributor.id}>{distributor.name}</option>)
                                    }
                                </select>
                            </div>
                        ) : null
                    }

                    <div>
                        <label htmlFor="date">{t("Select Date")}: </label>
                        <DatePicker value={`${startDate ? new Date(startDate).toLocaleDateString() : ""} - ${endDate ? new Date(endDate).toLocaleDateString() : ""}`} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} />
                    </div>

                    <div className="d-flex">
                        <button onClick={handleFilter} className="btn btn-primary btn-inline form-control me-3">
                            {t("Filter")}
                        </button>
                        <button onClick={handleReset} className="btn btn-secondary btn-inline form-control ms-3">
                            {t("Reset")}
                        </button>
                    </div>
                </div>
                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={summaryPointDataTable} paging={false} searching={false} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={summaryPointCsvData.data} headers={summaryPointCsvData.headers} filename={`floating_point_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={userPointDataTable} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={userPointCsvData.data} headers={userPointCsvData.headers} filename={`user_floating_point_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default FloatingPointReport;