import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class UserPerformance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			data: null,
			loading: true,
			// outletId : "",
			startDate: "",
			endDate: ""
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated || !this.props.admin.admin.user_performance) {
				return this.props.history.push("/admin/dashboard");
			} else {
				const { operatorId } = this.props.admin.admin;
				axios
					.post("/api/admin/report/user", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						this.setState({
							...this.state,
							loading: false,
							data: res.data.data
						});
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
					});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	// handleChange = e => this.setState({
	//     ...this.state,
	//     [e.target.id] : e.target.value
	// });

	handleDate = dates => {
		const [start, end] = dates;
		// console.log({start , end});
		this.setState({
			...this.state,
			startDate: start,
			endDate: end
		});
	};

	handleFilter = e => {
		const { outletId, startDate, endDate } = this.state;
		let sendThis = {
			operatorId: this.props.admin.admin.operatorId
		};

		if (outletId) {
			sendThis.outletId = outletId;
		}
		if (startDate && endDate) {
			sendThis.startDate = new Date(startDate);
			sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
		}
		this.setState({
			...this.state,
			loading: true
		});
		axios
			.post("/api/admin/report/user", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	handleReset = e => {
		this.setState({
			...this.state,
			outletId: "",
			startDate: "",
			endDate: "",
			loading: true
		});
		const operatorId = this.props.admin.admin.operatorId;
		axios
			.post("/api/admin/report/user", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	render() {
		let data = {
			columns: [
				{
					label: i18n.t("Name"),
					field: "userName",
					sort: "asc"
				},
				{
					label: i18n.t("Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Email"),
					field: "email",
					sort: "asc"
				},
				{
					label: i18n.t("Join Date"),
					field: "joinedDate",
					sort: "asc"
				},
				{
					label: i18n.t("Member ID"),
					field: "memberId",
					sort: "asc"
				},
				{
					label: i18n.t("Store"),
					field: "outlet",
					sort: "asc"
				},
				{
					label: i18n.t("Channel"),
					field: "channel",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									<ul>
										<li><b>{i18n.t("Registered")}</b>: {i18n.t("User registered but no transaction record")}</li>
										<li><b>{i18n.t("Active")}</b>: {i18n.t("User spending is less than")} {currency(this.props.admin?.admin?.country)}50</li>
										<li><b>{i18n.t("Tier 1")}</b>: {i18n.t("User spending is from")} {currency(this.props.admin?.admin?.country)}50 {i18n.t("until")} {currency(this.props.admin?.admin?.country)}199</li>
										<li><b>{i18n.t("Tier 2")}</b>: {i18n.t("User spending is")} {currency(this.props.admin?.admin?.country)}200 {i18n.t("and above")}</li>
									</ul>
								</Tooltip>
							}
						>
							<span>{i18n.t("Tier")}</span>
						</OverlayTrigger>
					),
					field: "tier",
					sort: "asc"
				},
				{
					label: i18n.t("Referrer"),
					field: "referrer",
					sort: "asc"
				},
				{
					label: i18n.t("Referrals"),
					field: "referrals",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of transactions for last 7 days")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Number of Usage (in week)")}</span>
						</OverlayTrigger>
					),
					field: "weekUsage",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of transactions for last 1 month")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Number of Usage (in month)")}</span>
						</OverlayTrigger>
					),
					field: "monthUsage",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total amount spent in all the transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total Spent")} ({currency(this.props.admin?.admin?.country)})</span>
						</OverlayTrigger>
					),
					field: "totalSpent",
					sort: "asc"
				},
				{
					label: i18n.t("Last Transaction Date"),
					field: "lastTransaction",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of vouchers collected")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Voucher Collected")}</span>
						</OverlayTrigger>
					),
					field: "voucherAcquired",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of vouchers used")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Voucher Spent")}</span>
						</OverlayTrigger>
					),
					field: "voucherUsed",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of washer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Washer Used")}</span>
						</OverlayTrigger>
					),
					field: "washerUsed",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of dryer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Dryer Used")}</span>
						</OverlayTrigger>
					),
					field: "dryerUsed",
					sort: "asc"
				}
			],
			rows: this.state.data ?? []
		};

		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				let data = {
					userName: this.state.data[i].userName,
					number: `=""${this.state.data[i].number}""`,
					email: this.state.data[i].email,
					joinedDate: this.state.data[i].joinedDate,
					memberId: this.state.data[i].memberId,
					outlet: this.state.data[i].outlet,
					channel: this.state.data[i].channel,
					tier: this.state.data[i].tier,
					referrer: this.state.data[i].referrer,
					referrals: this.state.data[i].referrals,
					weekUsage: this.state.data[i].weekUsage,
					monthUsage: this.state.data[i].monthUsage,
					totalStamps: this.state.data[i].totalStamps,
					tokenSpent: this.state.data[i].tokenSpent,
					totalSpent: this.state.data[i].totalSpent,
					currentToken: this.state.data[i].currentToken,
					lastTransaction: this.state.data[i].lastTransaction,
					voucherAcquired: this.state.data[i].voucherAcquired,
					voucherUsed: this.state.data[i].voucherUsed,
					washerUsed: this.state.data[i].washerUsed,
					dryerUsed: this.state.data[i].dryerUsed
				}
				csv.push(data);
			}
		}

		const csvData = {
			headers: [
				{ label: "Name", key: "userName" },
				{ label: "Number", key: "number" },
				{ label: "Email", key: "email" },
				{ label: "Join Date", key: "joinedDate" },
				{ label: "Member ID", key: "memberId" },
				{ label: "Store", key: "outlet" },
				{ label: "Channel", key: "channel" },
				{ label: "Tier", key: "tier" },
				{ label: "Referrer", key: "referrer" },
				{ label: "Referrals", key: "referrals" },
				{ label: "Number of Usage (in week)", key: "weekUsage" },
				{ label: "Number of Usage (in month)", key: "monthUsage" },
				{ label: `Total Spent (${currency(this.props.admin?.admin?.country)})`, key: "totalSpent" },
				{ label: "Last Transaction Date", key: "lastTransaction" },
				{ label: "Voucher Collected", key: "voucherAcquired" },
				{ label: "Voucher Spent", key: "voucherUsed" },
				{ label: "Washer Used", key: "washerUsed" },
				{ label: "Dryer Used", key: "dryerUsed" },
			],
			data: csv
		};

		if (this.props.admin.admin && this.props.admin.admin.token) {
			data.columns = [
				{
					label: i18n.t("Name"),
					field: "userName",
					sort: "asc"
				},
				{
					label: i18n.t("Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Email"),
					field: "email",
					sort: "asc"
				},
				{
					label: i18n.t("Join Date"),
					field: "joinedDate",
					sort: "asc"
				},
				{
					label: i18n.t("Member ID"),
					field: "memberId",
					sort: "asc"
				},
				{
					label: i18n.t("Store"),
					field: "outlet",
					sort: "asc"
				},
				{
					label: i18n.t("Channel"),
					field: "channel",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									<ul>
										<li><b>{i18n.t("Registered")}</b>: {i18n.t("User registered but no transaction record")}</li>
										<li><b>{i18n.t("Active")}</b>: {i18n.t("User spending is less than")} {currency(this.props.admin?.admin?.country)}50</li>
										<li><b>{i18n.t("Tier 1")}</b>: {i18n.t("User spending is from")} {currency(this.props.admin?.admin?.country)}50 {i18n.t("until")} {currency(this.props.admin?.admin?.country)}199</li>
										<li><b>{i18n.t("Tier 2")}</b>: {i18n.t("User spending is")} {currency(this.props.admin?.admin?.country)}200 {i18n.t("and above")}</li>
									</ul>
								</Tooltip>
							}
						>
							<span>{i18n.t("Tier")}</span>
						</OverlayTrigger>
					),
					field: "tier",
					sort: "asc"
				},
				{
					label: i18n.t("Referrer"),
					field: "referrer",
					sort: "asc"
				},
				{
					label: i18n.t("Referrals"),
					field: "referrals",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of transactions for last 7 days")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Number of Usage (in week)")}</span>
						</OverlayTrigger>
					),
					field: "weekUsage",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of transactions for last 1 month")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Number of Usage (in month)")}</span>
						</OverlayTrigger>
					),
					field: "monthUsage",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total amount spent in all the transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total Spent")} ({currency(this.props.admin?.admin?.country)})</span>
						</OverlayTrigger>
					),
					field: "totalSpent",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of e-Token spent in all the transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("e-Token Spent")}</span>
						</OverlayTrigger>
					),
					field: "tokenSpent",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Current e-Token")}
								</Tooltip>
							}
						>
							<span>{i18n.t("e-Tokens")}</span>
						</OverlayTrigger>
					),
					field: "currentToken",
					sort: "asc"
				},
				{
					label: i18n.t("Last Transaction Date"),
					field: "lastTransaction",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of vouchers collected")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Voucher Collected")}</span>
						</OverlayTrigger>
					),
					field: "voucherAcquired",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of vouchers used")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Voucher Spent")}</span>
						</OverlayTrigger>
					),
					field: "voucherUsed",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of washer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Washer Used")}</span>
						</OverlayTrigger>
					),
					field: "washerUsed",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of dryer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Dryer Used")}</span>
						</OverlayTrigger>
					),
					field: "dryerUsed",
					sort: "asc"
				}
			];

			csvData.headers = [
				{ label: "Name", key: "userName" },
				{ label: "Number", key: "number" },
				{ label: "Email", key: "email" },
				{ label: "Join Date", key: "joinedDate" },
				{ label: "Member ID", key: "memberId" },
				{ label: "Store", key: "outlet" },
				{ label: "Channel", key: "channel" },
				{ label: "Tier", key: "tier" },
				{ label: "Referrer", key: "referrer" },
				{ label: "Referrals", key: "referrals" },
				{ label: "Number of Usage (in week)", key: "weekUsage" },
				{ label: "Number of Usage (in month)", key: "monthUsage" },
				{ label: `Total Spent (${currency(this.props.admin?.admin?.country)})`, key: "totalSpent" },
				{ label: "e-Token Spent", key: "tokenSpent" },
				{ label: "e-Tokens", key: "currentToken" },
				{ label: "Last Transaction Date", key: "lastTransaction" },
				{ label: "Voucher Collected", key: "voucherAcquired" },
				{ label: "Voucher Spent", key: "voucherUsed" },
				{ label: "Washer Used", key: "washerUsed" },
				{ label: "Dryer Used", key: "dryerUsed" },
			]
		}

		if (this.props.admin.admin && this.props.admin.admin.stamp) {
			data.columns.push({
				label: (
					<OverlayTrigger
						placement="top"
						overlay={
							<Tooltip>
								{i18n.t("Total number of stamps collected")}
							</Tooltip>
						}
					>
						<span>{i18n.t("Total Stamps")}</span>
					</OverlayTrigger>
				),
				field: "totalStamps",
				sort: "asc"
			})

			csvData.headers.push({ label: "Total Stamps", key: "totalStamps" })
		}

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">{i18n.t("User Performance")}</h5>
							</div>

							<div className="card-body">
								<div className="mb-5">
									<h6>{i18n.t("Filter")}</h6>
									<div>
										<label htmlFor="date">{i18n.t("Select Join Date")}: </label>
										<DatePicker value={`${this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() : ""} - ${this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} />
									</div>

									<div className="d-flex">
										<button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">
											{i18n.t("Filter")}
										</button>
										<button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">
											{i18n.t("Reset")}
										</button>
									</div>
								</div>
								<div className="text-center mt-5">
									{
										this.state.loading ? <Loading /> : (
											<div>
												<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
												<div className="d-grid gap-2 d-md-flex justify-content-md-end">
													<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`user_performance_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
														{i18n.t("Download Report")}
													</CSVLink>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(UserPerformance);
