import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import join from "../../../../../assets/images/logo/join.png";

const LaundroCheck = ({ change, showButton, checkOperator }) => {

    const history = useHistory();
    const { t } = useTranslation();
    
    return (
        <div className="px-5 mt-5">
            <div className="text-center mb-4">
                <h3 className="font-weight-bold m-0 p-0">{t("WELCOME")}</h3>
                <img src={join} alt="join banner" className="img-fluid" />
            </div>

            <div>
                <button
                    id="register"
                    onClick={() => change("register")}
                    className="big-button form-control btn mb-2"
                >
                    {t("REGISTER")}
                </button>
                <button
                    id="login"
                    onClick={() => change("login")}
                    className="big-button form-control btn mb-2"
                >
                    {t("LOGIN")}
                </button>

                {showButton ? (
                    <Link
                        id="skip-to-payment"
                        to="/user/payment"
                        className="big-button-alt form-control btn mb-2"
                        onClick={checkOperator}
                    >
                        - {t("SKIP TO PAYMENT")} -
                    </Link>
                ) : null}

                <div className="text-center mt-3">
                    <p id="view-our-guideline" onClick={() => history.push("/faq")}>
                        {t("View our guideline")}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LaundroCheck;