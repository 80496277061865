import React, { Component } from "react";
import { connect } from "react-redux";
import Topbar from '../components/Topbar';
import Sidebar from "../components/Sidebar";
import Loading from '../../../admin/components/Loading';
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import VoucherType from "../../../admin/pages/charts/VoucherType";
import VoucherValue from "../../../admin/pages/charts/VoucherValue";
import { CSVLink } from "react-csv";
import i18n from "i18next";
import { dateFormat, timeFormat } from "../../../../dynamicController";

const mySwal = withReactContent(Swal);

class VoucherRedemption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: [],
            voucherType: [],
            voucherValue: [],
            loading: false,
            startDate: "",
            endDate: "",
            operatorId: "",
            distributorId: "",
            operators: [],
            voucherTypeOption: [],
            voucherTypeFilter: ""
        };
    }

    componentDidMount() {
        if (!this.props.client || !this.props.client.voucher_redemption) {
            return this.props.history.push('/client');
        } else {
            let options = ["Register", "Birthday", "Giveaway", "Referral", "Voucher Code"]
            if (this.props.client.token) {
                options.push("Reload")
            }

            if (this.props.client.stamp) {
                options.push("Stamp")
            }

            this.setState({
                ...this.state,
                voucherTypeOption: options
            });
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e =>
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });

    handleDate = dates => {
        const [start, end] = dates;
        this.setState({
            ...this.state,
            startDate: start,
            endDate: end
        });
    };

    handleFilter = e => {
        const { operatorId, startDate, endDate, distributorId, voucherTypeFilter } = this.state;
        if (!distributorId) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please select distributor"), "error");
        }
        // else if (!operatorId) {
        //     mySwal.fire("Error", "Please select the operator", "error");
        // } 
        else {
            let sendThis = {
                operatorId,
                distributorId
            };

            if (startDate && endDate) {
                sendThis.startDate = new Date(startDate);
                sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
            }

            if (voucherTypeFilter) {
                sendThis.voucherTypeFilter = voucherTypeFilter
            }
            this.setState({
                ...this.state,
                loading: true
            });
            axios
                .post("/api/admin/report/voucher", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data.data,
                        voucherType: res.data.voucherType,
                        voucherValue: res.data.voucherValue
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            operatorId: "",
            startDate: "",
            endDate: "",
            data: [],
            voucherType: [],
            voucherValue: [],
            distributorId: "",
            operators: [],
            voucherTypeFilter: ""
        });
    };

    handleChangeDistributor = e => {

        const selectedOperator = this.props.client.admin.filter(admin => admin.distributorId === e.target.value);

        this.setState({
            ...this.state,
            operators: selectedOperator,
            [e.target.id]: e.target.value,
            operatorId: ""
        });
    }

    render() {
        let data = {
            columns: [
                {
					label: i18n.t("Member ID"),
					field: "member_id",
					sort: "asc"
				},
                {
                    label: i18n.t("User Name"),
                    field: "name",
                    sort: "asc"
                },
                {
                    label: i18n.t("Number"),
                    field: "number",
                    sort: "asc"
                },
                {
					label: i18n.t("Email"),
					field: "email",
					sort: "asc"
				},
                {
                    label: i18n.t("Amount"),
                    field: "voucherAmount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Grant Date"),
                    field: "grantDate",
                    sort: "asc"
                },
                {
                    label: i18n.t("Claim Date"),
                    field: "claimDate",
                    sort: "asc"
                },
                {
                    label: i18n.t("Status"),
                    field: "available",
                    sort: "asc"
                },
                {
                    label: i18n.t("Voucher Type"),
                    field: "from",
                    sort: "asc"
                },
                {
                    label: i18n.t("Washer Use"),
                    field: "washer_use",
                    sort: "asc"
                },
                {
                    label: i18n.t("Dryer Use"),
                    field: "dryer_use",
                    sort: "asc"
                },
                {
                    label: i18n.t("Package Use"),
                    field: "package_use",
                    sort: "asc"
                },
            ],
            rows: this.state.data
        };

        let csv = [];

        if (this.state.data.length > 0) {
            for (let i = 0; i < this.state.data.length; i++) {
                let data = {
                    member_id: this.state.data[i].member_id,
                    name: this.state.data[i].name,
                    number: `=""${this.state.data[i].number}""`,
                    email: this.state.data[i].email,
                    voucherAmount: this.state.data[i].voucherAmount,
                    grantDate: this.state.data[i].grantDate,
                    claimDate: this.state.data[i].claimDate,
                    available: this.state.data[i].available,
                    from: this.state.data[i].from,
                    washer_use: this.state.data[i].washer_use,
					dryer_use: this.state.data[i].dryer_use,
					package_use: this.state.data[i].package_use
                }
                csv.push(data);
            }
        }

        const csvData = {
            headers: [
                { label: "Member ID", key: "member_id" },
                { label: "User Name", key: "name" },
                { label: "Number", key: "number" },
                { label: "Email", key: "email" },
                { label: "Amount", key: "voucherAmount" },
                { label: "Grant Date", key: "grantDate" },
                { label: "Claim Date", key: "claimDate" },
                { label: "Status", key: "available" },
                { label: "Voucher Type", key: "from" },
                { label: "Washer Use", key: "washer_use" },
                { label: "Dryer Use", key: "dryer_use" },
                { label: "Package Use", key: "package_use" },
            ],
            data: csv
        };

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Voucher Redemption")}</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <div>
                                        <label htmlFor="distributorId">{i18n.t("Select Distributor")}: </label>
                                        <select id="distributorId" name="distributorId" className="browser-default form-select" value={this.state.distributorId} onChange={this.handleChangeDistributor}>
                                            <option disabled value="">{i18n.t("Select Distributor")}</option>
                                            {
                                                this.props.client.distributor.map(distributor => <option key={distributor.id} value={distributor.id}>{distributor.name}</option>)
                                            }
                                        </select>
                                    </div>

                                    {/* <div>
                                        <label htmlFor="operatorId">Select Operator: </label>
                                        <select id="operatorId" name="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleChange}>
                                            <option disabled value="">Select Operator</option>
                                            {
                                                this.state.distributorId ? this.state.operators.map(admin => <option key={admin.id} value={admin.id}>{admin.username}</option>) : null
                                            }
                                        </select>
                                    </div> */}

                                    <div>
                                        <label htmlFor="voucherTypeFilter">{i18n.t("Select Voucher Type")}: </label>
                                        <select id="voucherTypeFilter" name="voucherTypeFilter" className="browser-default form-select" value={this.state.voucherTypeFilter} onChange={this.handleChange}>
                                            <option disabled value="">{i18n.t("Select Voucher Type")}</option>
                                            {
                                                this.state.voucherTypeOption.map((data, i) => <option key={i} value={data}>{i18n.t(data)}</option>)
                                            }
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="date">{i18n.t("Select Grant Date")}: </label>
                                        <DatePicker value={`${this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() : ""} - ${this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} />
                                    </div>

                                    <div className="d-flex">
                                        <button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">
                                            {i18n.t("Filter")}
                                        </button>
                                        <button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">
                                            {i18n.t("Reset")}
                                        </button>
                                    </div>
                                </div>
                                <div className="text-center mt-5">{this.state.loading ? <Loading /> : (
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "50%" }}>
                                                <VoucherType voucherData={this.state.voucherType} />
                                            </div>
                                            <div className="ps-5" style={{ width: "50%" }}>
                                                <VoucherValue voucherData={this.state.voucherValue} />
                                            </div>
                                        </div>

                                        <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`voucher_redemption_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                {i18n.t("Download Report")}
                                            </CSVLink>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(VoucherRedemption);
