import { STAFF_LOGIN, IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN, SAVE_STAFF_REDIRECT_URL, SAVE_STAFF_USER_TYPE } from './type';
import axios from 'axios';

export const staffLogin = data => dispatch => {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: IS_LOADING });
    axios.post('/api/staff/auth/login', data)
        .then(res => {
            dispatch({ type: STAFF_LOGIN, payload: res.data.data });
            dispatch({ type: IS_LOADED });
        })
        .catch(err => {
            dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
            dispatch({ type: IS_LOADED });
        });
};

// check token for session
export const checkToken = () => (dispatch, getState) => {
    if (getState().staff.token) {
        axios.post('/api/staff/auth/checkToken', { token: getState().staff.token })
            .then(res => dispatch({ type: STAFF_LOGIN, payload: res.data.data }))
            .catch(err => {
                console.log(err);
                dispatch({ type: REMOVE_TOKEN });
            });
    }
};

export const staffTokenLogin = token => dispatch => {
    axios
        .post("/api/staff/auth/tokenLogin", { token })
        .then(res => dispatch({ type: STAFF_LOGIN, payload: res.data.data }))
        .catch(err => {
            console.log(err);
            dispatch({ type: REMOVE_TOKEN });
        });
};

export const saveStaffRedirectUrl = url => {
    return {
        type: SAVE_STAFF_REDIRECT_URL,
        payload: url
    };
};

export const saveStaffUserType = data => {
    return {
        type: SAVE_STAFF_USER_TYPE,
        payload: data
    };
};

export const staffLogout = () => {
    return {
        type: REMOVE_TOKEN
    };
};