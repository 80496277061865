import { clientLogout } from "../../../../redux/actions/clientActions";
import { changeLanguage } from "../../../../redux/actions/userActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { distributorRedirectUrl, laundroText } from "../../../../dynamicController";
import { useTranslation } from "react-i18next";

const Topbar = ({ handleToggle, logout, redirectUrl, userType, changeLanguage, language }) => {

	const history = useHistory();
	const { t } = useTranslation();

	return (
		<nav className="navbar">
			<div>
				<button onClick={() => handleToggle()}>
					<i style={{ fontSize: "20px" }} className="bi bi-list"></i>
				</button>
			</div>

			<div className="d-flex">
				<div className="m-auto pe-2">
					<select
						name="language"
						id="language"
						className="form-select"
						value={language}
						onChange={e => changeLanguage(e.target.value)}
						required
					>
						<option value="en" id="en">
							English
						</option>
						<option value="ms" id="ms">
							Malay
						</option>
						<option value="zh" id="zh">
							Chinese (Simplified)
						</option>
						<option value="zhtw" id="zhtw">
							Chinese (Traditional)
						</option>
					</select>
				</div>

				<div className="dropdown">
					<button type="button" className="dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
						<i style={{ fontSize: "40px", display: "inline-block" }} className="bi bi-person-circle"></i>
					</button>

					<div style={{ transform: "translate(-70px , 0px)" }} className="dropdown-menu m-0" aria-labelledby="dropdownMenu">
						<div className="dropdown-item">
							<button
								onClick={() => {
									logout();
									history.push("/client");
								}}
								className="btn"
							>
								{t("Logout")}
							</button>
						</div>

						<a href={redirectUrl ? `${redirectUrl}?userType=${userType}&language=${language}` : distributorRedirectUrl()} className="dropdown-item py-3" style={{ color: "black" }}>
							{t("Back To")} {laundroText()}
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
};

const mapStateToProps = state => {
	return {
		redirectUrl: state.client.redirectUrl,
		userType: state.client.userType,
		language: state.user.language,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(clientLogout()),
		changeLanguage: (language) => dispatch(changeLanguage(language))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
