import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import Navigator from "../Navigation";
import { currency } from "../../../../dynamicController";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const PackDetails = ({ user }) => {
    const history = useHistory();
    const location = useLocation();
    const [list, setList] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (!user) {
            history.push('/')
        } else if (!location.state) {
            history.push('/')
        } else if (location.state.transaction) {
            const { transaction_items } = location.state.transaction
            let itemList = []
            for (let i = 0; i < transaction_items.length; i++) {
                let pack = itemList.filter(item => item.sequence === transaction_items[i].sequenceId)[0]
                if (pack) {
                    if (transaction_items[i].name === "Wash") {
                        pack.wash = true
                        pack.washCapacity = transaction_items[i].capacity
                        pack.washMode = transaction_items[i].mode
                        pack.washPrice = transaction_items[i].price
                        pack.washStatus = transaction_items[i].status
                    } else if (transaction_items[i].name === "Dry") {
                        pack.dry = true
                        pack.dryCapacity = transaction_items[i].capacity
                        pack.dryDuration = transaction_items[i].machine_duration
                        pack.dryPrice = transaction_items[i].price
                        pack.dryStatus = transaction_items[i].status
                    } else if (transaction_items[i].name === "Fold") {
                        pack.fold = true
                        pack.foldPrice = transaction_items[i].price
                        pack.foldStatus = transaction_items[i].status
                    }
                } else {
                    let tempObject = {
                        sequence: transaction_items[i].sequenceId,
                        size: transaction_items[i].size
                    }
                    if (transaction_items[i].name === "Wash") {
                        tempObject.wash = true
                        tempObject.washCapacity = transaction_items[i].capacity
                        tempObject.washMode = transaction_items[i].mode
                        tempObject.washPrice = transaction_items[i].price
                        tempObject.washStatus = transaction_items[i].status
                    } else if (transaction_items[i].name === "Dry") {
                        tempObject.dry = true
                        tempObject.dryCapacity = transaction_items[i].capacity
                        tempObject.dryDuration = transaction_items[i].machine_duration
                        tempObject.dryPrice = transaction_items[i].price
                        tempObject.dryStatus = transaction_items[i].status
                    } else if (transaction_items[i].name === "Fold") {
                        tempObject.fold = true
                        tempObject.foldPrice = transaction_items[i].price
                        tempObject.foldStatus = transaction_items[i].status
                    }
                    itemList.push(tempObject)
                }
            }
            setList(itemList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="trans-details-page">
            <div id="user-header">
                <h5>
                    <strong onClick={() => history.goBack()}>
                        <i className="bi bi-chevron-left me-3"></i> {t("Back")}
                    </strong>
                </h5>
            </div>
            <div className="pb-5">
                {
                    list.map((pack, index) => {
                        return (
                            <div key={index} className="payment-section">
                                <div className="d-flex justify-content-between">
                                    <p><b>{t("Pack Number")}</b></p>
                                    <p><b>{pack.sequence}</b></p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>{t("Size")}</p>
                                    <p>{pack.size}</p>
                                </div>
                                {
                                    pack.wash ? (
                                        <div>
                                            <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                            <p><b>{t("Wash")}</b></p>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Mode")}</p>
                                                <p>{t(pack.washMode)}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Capacity")}</p>
                                                <p>{pack.washCapacity}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Price")}</p>
                                                <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.washPrice} ${t("credit")}` : `${currency(user.country)} ${pack.washPrice}`}</b></p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Status")}</p>
                                                <p style={{ color: pack.washStatus === "Completed" ? "green" : "#FF3C00" }}>{t(pack.washStatus)}</p>
                                            </div>
                                        </div>
                                    ) : null
                                }
                                {
                                    pack.dry ? (
                                        <div>
                                            <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                            <p><b>{t("Dry")}</b></p>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Duration")}</p>
                                                <p>{pack.dryDuration} {t("min")}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Capacity")}</p>
                                                <p>{pack.dryCapacity}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Price")}</p>
                                                <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.dryPrice} ${t("credit")}` : `${currency(user.country)} ${pack.dryPrice}`}</b></p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Status")}</p>
                                                <p style={{ color: pack.dryStatus === "Completed" ? "green" : "#FF3C00" }}>{t(pack.dryStatus)}</p>
                                            </div>
                                        </div>
                                    ) : null
                                }
                                {
                                    pack.fold ? (
                                        <div>
                                            <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                            <p><b>{t("Fold")}</b></p>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Price")}</p>
                                                <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.foldPrice} ${t("credit")}` : `${currency(user.country)} ${pack.foldPrice}`}</b></p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p>{t("Status")}</p>
                                                <p style={{ color: pack.foldStatus === "Completed" ? "green" : "#FF3C00" }}>{t(pack.foldStatus)}</p>
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>
                        )
                    })
                }
            </div>
            <Navigator />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

export default connect(mapStateToProps, null)(PackDetails);