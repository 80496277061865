import { SCANNED_QRCODE, SAVE_CREDS, SAVE_TRANSACTION_ID } from "../actions/type";

const initState = {
	machine: null,
	outlet: {
		machineNo: localStorage.getItem("qr-machineNo") || null,
		outletCode: localStorage.getItem("qr-outletCode") || null,
		operatorCode: localStorage.getItem("qr-operatorCode") || null
	},
	transactionId: localStorage.getItem("transactionId") || null
};

const paymentReducer = (state = initState, action) => {
	switch (action.type) {
		case SCANNED_QRCODE:
			return {
				...state,
				machine: action.payload
			};
		case SAVE_CREDS:
			if (action.payload.machineNo) {
				localStorage.setItem("qr-machineNo", action.payload.machineNo);
			}
			localStorage.setItem("qr-outletCode", action.payload.outletCode);
			localStorage.setItem("qr-operatorCode", action.payload.operatorCode);
			return {
				...state,
				outlet: action.payload
			};
		case SAVE_TRANSACTION_ID:
			localStorage.setItem("transactionId", action.payload);
			return {
				...state,
				transactionId: action.payload
			};
		default:
			return state;
	}
};

export default paymentReducer;
