import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, USER_REGISTERED, USER_LOGIN, REMOVE_TOKEN, UPDATE_USER, CHANGE_PAGE, SAVE_OPERATORID, CHANGE_LANGUAGE, CHANGE_NAV, CHANGE_COUNTER, SAVE_USER_TOKEN } from "../actions/type";
import i18n from "../../i18n";

// Function to add our give data into cache
// const addDataIntoCache = (cacheName, url, response) => {
// 	// Converting our response into Actual Response form
// 	const data = new Response(JSON.stringify(response));

// 	if ('caches' in window) {
// 		// Opening given cache and putting our data into it
// 		caches.open(cacheName).then((cache) => {
// 			cache.put(url, data);
// 		});
// 	}
// };

// const getCacheData = (cacheName, url) => {
// 	caches.open(cacheName)
// 	.then((cache) => {
// 		cache.match(url)
// 		.then(res => res.json())
// 			.then(data => {
// 				console.log(data);
// 			})
// 			.catch(err => {
// 				console.log(err)
// 			})
// 	})
// 	.catch(err => {
// 		console.log(err)
// 	})
// }

const initState = {
	isLoading: false,
	error: null,
	token: localStorage.getItem("laundry-user"),
	isAuthenticated: false,
	user: null,
	authProgress: "landing",
	operatorId: localStorage.getItem("operatorId"),
	language: localStorage.getItem("language") || window.navigator.language.substring(0, 2),
	nav: "stamp",
	modalCounter: localStorage.getItem("counter") || 0,
	loginId: localStorage.getItem("loyalty-login-id") || null,
};

const userReducer = (state = initState, action) => {
	// console.log(action);
	switch (action.type) {
		case IS_LOADING:
			return {
				...state,
				isLoading: true
			};
		case IS_LOADED:
			return {
				...state,
				isLoading: false
			};
		case SHOW_ERROR:
			return {
				...state,
				error: action.payload
			};
		case CLEAR_ERROR:
			return {
				...state,
				error: null
			};
		case CHANGE_PAGE:
			return {
				...state,
				authProgress: action.payload,
				error: null
			};
		case CHANGE_NAV:
			return {
				...state,
				nav: action.payload,
				error: null
			};
		case USER_REGISTERED:
			return {
				...state,
				authProgress: action.payload.login && !action.payload.register ? "login" : action.payload.register && !action.payload.login ? "register" : "landing"
			};
		case USER_LOGIN:
			let id = state.loginId
			if (!state.loginId) {
				id = `${action.payload.user.store}:${action.payload.user.memberId}`
				localStorage.setItem("loyalty-login-id", id);
			} else {
				if (state.loginId.split(",").filter(data => data === `${action.payload.user.store}:${action.payload.user.memberId}`).length <= 0) {
					id = state.loginId.concat(`,${action.payload.user.store}:${action.payload.user.memberId}`)
					localStorage.setItem("loyalty-login-id", id);
				}
			}
			localStorage.setItem("laundry-user", action.payload.token);
			return {
				...state,
				user: action.payload.user,
				token: action.payload.token,
				isAuthenticated: true,
				authProgress: "landing",
				loginId: id
			};
		case UPDATE_USER:
			if (action.payload.type === "image") {
				return {
					...state,
					user: {
						...state.user,
						profilePicture: action.payload.data
					}
				};
			} else if (action.payload.type === "name") {
				return {
					...state,
					user: {
						...state.user,
						name: action.payload.data
					}
				};
			} else if (action.payload.type === "email") {
				return {
					...state,
					user: {
						...state.user,
						email: action.payload.data
					}
				};
			} else if (action.payload.type === "number") {
				return {
					...state,
					user: {
						...state.user,
						number: action.payload.data
					}
				};
			} else if (action.payload.type === "stamp") {
				return {
					...state,
					user: {
						...state.user,
						...action.payload.data
					}
				};
			} else if (action.payload.type === "birthday") {
				return {
					...state,
					user: {
						...state.user,
						birthday: action.payload.data
					}
				};
			} else if (action.payload.type === "voucher") {
				return {
					...state,
					user: {
						...state.user,
						vouchers: action.payload.voucher,
						voucherScheduler: action.payload.voucherScheduler
					}
				};
			}
			break;
		case REMOVE_TOKEN:
			localStorage.removeItem("laundry-user");
			localStorage.removeItem("counter");
			return {
				...state,
				token: null,
				isLoading: false,
				error: null,
				isAuthenticated: false,
				user: null,
				authProgress: "landing",
				modalCounter: 0
			};
		case SAVE_OPERATORID:
			localStorage.setItem("operatorId", action.payload);
			//addDataIntoCache('operatorId', `https://${window.location.hostname}`, action.payload)
			return {
				...state,
				operatorId: action.payload
			};
		case SAVE_USER_TOKEN:
			localStorage.setItem("laundry-user", action.payload);
			return {
				...state,
				token: action.payload,
			};
		case CHANGE_LANGUAGE:
			localStorage.setItem("language", action.payload);
			i18n.changeLanguage(action.payload);
			return {
				...state,
				language: action.payload
			};
		case CHANGE_COUNTER:
			localStorage.setItem("counter", action.payload);
			return {
				...state,
				modalCounter: action.payload
			};
		default:
			return state;
	}
};

export default userReducer;
