import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from './Loading';
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Tabs, Tab } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { dateFormat, timeFormat } from '../../../dynamicController';

const mySwal = withReactContent(Swal);

const RewardHistory = ({ operatorId, authtoken, stamp, etoken, point, role }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true)
        axios
            .post("/api/admin/setting/reward/history", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": authtoken } })
            .then(res => {
                setData(res.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                mySwal.fire(t("Error"), t(err.response.data.error), "error")
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let csv = []

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const csvDataObject = {
                date: data[i].date,
                rewardBy: data[i].rewardBy,
                distributorName: data[i].distributorName,
                name: data[i].name,
                number: `=""${data[i].number}""`,
                email: data[i].email,
                memberId: data[i].memberId,
                rewardType: data[i].rewardType,
                remark: data[i].remark,
                voucherId: data[i].voucherId,
                voucherType: data[i].voucherType,
                voucherAmount: data[i].voucherAmount,
                washerStamp: data[i].washerStamp,
                dryerStamp: data[i].dryerStamp,
                tokenAmount: data[i].tokenAmount,
                pointAmount: data[i].pointAmount,
            }
            csv.push(csvDataObject)
        }
    }

    let allTableData = {
        columns: [
            {
                label: t("Reward Date"),
                field: "date",
                sort: "asc"
            },
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "memberId",
                sort: "asc"
            },
            {
                label: t("Reward Type"),
                field: "rewardType",
                sort: "asc"
            },
            {
                label: t("Remark"),
                field: "remark",
                sort: "asc"
            },
            {
                label: t("Voucher ID"),
                field: "voucherId",
                sort: "asc"
            },
            {
                label: t("Voucher Type"),
                field: "voucherType",
                sort: "asc"
            },
            {
                label: t("Voucher Amount"),
                field: "voucherAmount",
                sort: "asc"
            },
        ],
        rows: data
    };

    const allTableCsvData = {
        headers: [
            { label: "Reward Date", key: "date" },
            { label: "User Name", key: "name" },
            { label: "Phone Number", key: "number" },
            { label: "Email", key: "email" },
            { label: "Member ID", key: "memberId" },
            { label: "Reward Type", key: "rewardType" },
            { label: "Remark", key: "remark" },
            { label: "Voucher ID", key: "voucherId" },
            { label: "Voucher Type", key: "voucherType" },
            { label: "Voucher Amount", key: "voucherAmount" },
        ],
        data: csv
    }

    let voucherTableData = {
        columns: [
            {
                label: t("Reward Date"),
                field: "date",
                sort: "asc"
            },
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "memberId",
                sort: "asc"
            },
            {
                label: t("Reward Type"),
                field: "rewardType",
                sort: "asc"
            },
            {
                label: t("Remark"),
                field: "remark",
                sort: "asc"
            },
            {
                label: t("Voucher ID"),
                field: "voucherId",
                sort: "asc"
            },
            {
                label: t("Voucher Type"),
                field: "voucherType",
                sort: "asc"
            },
            {
                label: t("Voucher Amount"),
                field: "voucherAmount",
                sort: "asc"
            },
        ],
        rows: data.filter(data => data.rewardType === "Voucher")
    };

    const voucherCsvData = {
        headers: [
            { label: "Reward Date", key: "date" },
            { label: "User Name", key: "name" },
            { label: "Phone Number", key: "number" },
            { label: "Email", key: "email" },
            { label: "Member ID", key: "memberId" },
            { label: "Reward Type", key: "rewardType" },
            { label: "Remark", key: "remark" },
            { label: "Voucher ID", key: "voucherId" },
            { label: "Voucher Type", key: "voucherType" },
            { label: "Voucher Amount", key: "voucherAmount" },
        ],
        data: csv.filter(data => data.rewardType === "Voucher")
    }

    let stampTableData = {
        columns: [
            {
                label: t("Reward Date"),
                field: "date",
                sort: "asc"
            },
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "memberId",
                sort: "asc"
            },
            {
                label: t("Reward Type"),
                field: "rewardType",
                sort: "asc"
            },
            {
                label: t("Remark"),
                field: "remark",
                sort: "asc"
            },
            {
                label: t("Washer Stamp"),
                field: "washerStamp",
                sort: "asc"
            },
            {
                label: t("Dryer Stamp"),
                field: "dryerStamp",
                sort: "asc"
            },
        ],
        rows: data.filter(data => data.rewardType === "Stamp")
    };

    const stampCsvData = {
        headers: [
            { label: "Reward Date", key: "date" },
            { label: "User Name", key: "name" },
            { label: "Phone Number", key: "number" },
            { label: "Email", key: "email" },
            { label: "Member ID", key: "memberId" },
            { label: "Reward Type", key: "rewardType" },
            { label: "Remark", key: "remark" },
            { label: "Washer Stamp", key: "washerStamp" },
            { label: "Dryer Stamp", key: "dryerStamp" },
        ],
        data: csv.filter(data => data.rewardType === "Stamp")
    }

    let tokenTableData = {
        columns: [
            {
                label: t("Reward Date"),
                field: "date",
                sort: "asc"
            },
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "memberId",
                sort: "asc"
            },
            {
                label: t("Reward Type"),
                field: "rewardType",
                sort: "asc"
            },
            {
                label: t("Remark"),
                field: "remark",
                sort: "asc"
            },
            {
                label: t("e-Token Amount"),
                field: "tokenAmount",
                sort: "asc"
            },
        ],
        rows: data.filter(data => data.rewardType === "Token")
    };

    const tokenCsvData = {
        headers: [
            { label: "Reward Date", key: "date" },
            { label: "User Name", key: "name" },
            { label: "Phone Number", key: "number" },
            { label: "Email", key: "email" },
            { label: "Member ID", key: "memberId" },
            { label: "Reward Type", key: "rewardType" },
            { label: "Remark", key: "remark" },
            { label: "e-Token Amount", key: "tokenAmount" },
        ],
        data: csv.filter(data => data.rewardType === "Token")
    }

    let pointTableData = {
        columns: [
            {
                label: t("Reward Date"),
                field: "date",
                sort: "asc"
            },
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "memberId",
                sort: "asc"
            },
            {
                label: t("Reward Type"),
                field: "rewardType",
                sort: "asc"
            },
            {
                label: t("Remark"),
                field: "remark",
                sort: "asc"
            },
            {
                label: t("Point Amount"),
                field: "pointAmount",
                sort: "asc"
            },
        ],
        rows: data.filter(data => data.rewardType === "Point")
    };

    const pointCsvData = {
        headers: [
            { label: "Reward Date", key: "date" },
            { label: "User Name", key: "name" },
            { label: "Phone Number", key: "number" },
            { label: "Email", key: "email" },
            { label: "Member ID", key: "memberId" },
            { label: "Reward Type", key: "rewardType" },
            { label: "Remark", key: "remark" },
            { label: "Point Amount", key: "pointAmount" },
        ],
        data: csv.filter(data => data.rewardType === "Point")
    }

    if (stamp) {
        allTableData.columns.push(
            {
                label: t("Washer Stamp"),
                field: "washerStamp",
                sort: "asc"
            },
            {
                label: t("Dryer Stamp"),
                field: "dryerStamp",
                sort: "asc"
            },
        );

        allTableCsvData.headers.push(
            { label: "Washer Stamp", key: "washerStamp" },
            { label: "Dryer Stamp", key: "dryerStamp" },
        )
    }

    if (etoken) {
        allTableData.columns.push(
            {
                label: t("e-Token Amount"),
                field: "tokenAmount",
                sort: "asc"
            },
        );

        allTableCsvData.headers.push({ label: "e-Token Amount", key: "tokenAmount" })
    }

    if (point) {
        allTableData.columns.push(
            {
                label: t("Point Amount"),
                field: "pointAmount",
                sort: "asc"
            },
        );

        allTableCsvData.headers.push({ label: "Point Amount", key: "pointAmount" })
    }

    if (role === "distributor" || role === "client") {
        allTableData.columns.splice(1, 0, {
            label: t("Reward By"),
            field: "rewardBy",
            sort: "asc"
        })
        allTableCsvData.headers.splice(1, 0, { label: "Reward By", key: "rewardBy" })

        voucherTableData.columns.splice(1, 0, {
            label: t("Reward By"),
            field: "rewardBy",
            sort: "asc"
        })
        voucherCsvData.headers.splice(1, 0, { label: "Reward By", key: "rewardBy" })

        stampTableData.columns.splice(1, 0, {
            label: t("Reward By"),
            field: "rewardBy",
            sort: "asc"
        })
        stampCsvData.headers.splice(1, 0, { label: "Reward By", key: "rewardBy" })

        tokenTableData.columns.splice(1, 0, {
            label: t("Reward By"),
            field: "rewardBy",
            sort: "asc"
        })
        tokenCsvData.headers.splice(1, 0, { label: "Reward By", key: "rewardBy" })

        pointTableData.columns.splice(1, 0, {
            label: t("Reward By"),
            field: "rewardBy",
            sort: "asc"
        })
        pointCsvData.headers.splice(1, 0, { label: "Reward By", key: "rewardBy" })
    }

    if (role === "client") {
        allTableData.columns.splice(2, 0, {
            label: t("Distributor"),
            field: "distributorName",
            sort: "asc"
        })
        allTableCsvData.headers.splice(2, 0, { label: "Distributor", key: "distributorName" })

        voucherTableData.columns.splice(2, 0, {
            label: t("Distributor"),
            field: "distributorName",
            sort: "asc"
        })
        voucherCsvData.headers.splice(2, 0, { label: "Distributor", key: "distributorName" })

        stampTableData.columns.splice(2, 0, {
            label: t("Distributor"),
            field: "distributorName",
            sort: "asc"
        })
        stampCsvData.headers.splice(2, 0, { label: "Distributor", key: "distributorName" })

        tokenTableData.columns.splice(2, 0, {
            label: t("Distributor"),
            field: "distributorName",
            sort: "asc"
        })
        tokenCsvData.headers.splice(2, 0, { label: "Distributor", key: "distributorName" })

        pointTableData.columns.splice(2, 0, {
            label: t("Distributor"),
            field: "distributorName",
            sort: "asc"
        })
        pointCsvData.headers.splice(2, 0, { label: "Distributor", key: "distributorName" })
    }

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <h5 className="card-title">{t("Reward History")}</h5>
            </div>

            <div className="card-body text-center">
                {
                    loading ? <Loading /> : (
                        <Tabs defaultActiveKey="all">
                            <Tab eventKey="all" title={t("All")}>
                                <MDBDataTable data={allTableData} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={allTableCsvData.data} headers={allTableCsvData.headers} filename={`reward_history_all_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download All Data")}
                                    </CSVLink>
                                </div>
                            </Tab>
                            <Tab eventKey="voucher" title={t("Voucher")}>
                                <MDBDataTable data={voucherTableData} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={voucherCsvData.data} headers={voucherCsvData.headers} filename={`reward_history_voucher_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Voucher Data")}
                                    </CSVLink>
                                </div>
                            </Tab>
                            {
                                stamp ? (
                                    <Tab eventKey="stamp" title={t("Stamp")}>
                                        <MDBDataTable data={stampTableData} noBottomColumns striped hover responsive bordered />
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CSVLink className="btn btn-primary" data={stampCsvData.data} headers={stampCsvData.headers} filename={`reward_history_stamp_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                {t("Download Stamp Data")}
                                            </CSVLink>
                                        </div>
                                    </Tab>
                                ) : null
                            }
                            {
                                etoken ? (
                                    <Tab eventKey="token" title={t("Token")}>
                                        <MDBDataTable data={tokenTableData} noBottomColumns striped hover responsive bordered />
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CSVLink className="btn btn-primary" data={tokenCsvData.data} headers={tokenCsvData.headers} filename={`reward_history_token_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                {t("Download Token Data")}
                                            </CSVLink>
                                        </div>
                                    </Tab>
                                ) : null
                            }
                            {
                                point ? (
                                    <Tab eventKey="point" title={t("Point")}>
                                        <MDBDataTable data={pointTableData} noBottomColumns striped hover responsive bordered />
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CSVLink className="btn btn-primary" data={pointCsvData.data} headers={pointCsvData.headers} filename={`reward_history_point_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                {t("Download Point Data")}
                                            </CSVLink>
                                        </div>
                                    </Tab>
                                ) : null
                            }
                        </Tabs>
                    )
                }
            </div>
        </div>
    )
}

export default RewardHistory;