import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from "../../admin/components/Loading";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class FaqList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: true,
            data: [],
        };
    };

    componentDidMount() {
        if (!this.props.distributor || !this.props.distributor.faq_setting) this.props.history.push("/distributor");
        else {
            axios
                .post("/api/admin/setting/getFaq", { id: this.props.distributor.distributorId, role: "distributor", faqGroupId: this.props.location.state.faqGroupId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    deleteFaq = id => {
        mySwal.fire({
            title: i18n.t("Confirmation"),
            text: i18n.t("Are you sure you want to delete FAQ?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: i18n.t("No"),
            confirmButtonText: i18n.t("Yes"),
            cancelButtonColor: "#d33"
        }).then(click => {

            if (click.isConfirmed && click.value) {
                this.setState({
                    ...this.state,
                    loading: true
                });

                axios.post('/api/admin/setting/deleteFaq', { id }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                    .then(res => {

                        axios
                            .post("/api/admin/setting/getFaq", { id: this.props.distributor.distributorId, role: "distributor", faqGroupId: this.props.location.state.faqGroupId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                            .then(res => {
                                this.setState({
                                    ...this.state,
                                    data: res.data,
                                    loading: false
                                });
                                mySwal.fire(i18n.t("Success"), i18n.t("FAQ deleted successfully"), "success");
                            })
                            .catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                            });

                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                        mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                    });
            }
        });
    }

    render() {

        let tableData = {
            columns: [
                {
                    label: i18n.t("Question"),
                    field: "question",
                    sort: "asc"
                },
                {
                    label: i18n.t("Answer"),
                    field: "answer",
                    sort: "asc"
                },
                {
                    label: i18n.t("Action"),
                    field: "action"
                }
            ],
            rows: []
        };

        if (this.state.data.length) {
            for (let i = 0; i < this.state.data.length; i++) {
                const dataObject = {
                    question: this.state.data[i].question,
                    answer: (
                        <div dangerouslySetInnerHTML={{__html: this.state.data[i].answer}}></div>
                    ),
                    action: (
                        <div>
                            <i className="bi bi-pencil-square" onClick={() => this.props.history.push("/distributor/faq/edit", { faqId: this.state.data[i].id, faqGroupId: this.props.location.state.faqGroupId, faqGroupTitle: this.props.location.state.faqGroupTitle })}></i>
                            <i className="bi bi-trash3-fill ms-3" onClick={() => this.deleteFaq(this.state.data[i].id)}></i>
                        </div>
                    )
                };
                tableData.rows.push(dataObject);

            }
        }

        const renderList = () => {

            if (this.state.loading) {
                return (
                    <Loading />
                );
            } else {
                if (!this.state.data.length) {
                    return (
                        <div className="text-center">
                            <h1> - {i18n.t("NO FAQs")} - </h1>
                        </div>
                    );
                } else {
                    return (
                        <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                    );
                }
            }
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title">{i18n.t("FAQ")} {this.props.location.state.faqGroupTitle ? `(${this.props.location.state.faqGroupTitle})` : ""}</h5>

                                <button type="button" className="btn btn-primary" onClick={e => this.props.history.push("/distributor/faq/add", { faqGroupId: this.props.location.state.faqGroupId, faqGroupTitle: this.props.location.state.faqGroupTitle })}>
                                    {i18n.t("Add FAQ")}
                                </button>
                            </div>

                            <div className="card-body">
                                {
                                    renderList()
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(FaqList);