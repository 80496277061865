import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import UserCompensation from "../components/UserCompensation";

const Giveaway = ({ admin, token }) => {

	const history = useHistory();

	const [toggled, setToggled] = useState(false)

	useEffect(() => {
		if (!admin || !admin.user_reward) history.push("/admin/dashboard");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="admin-page-container">
			<Sidebar toggled={toggled} setToggled={e => setToggled(!toggled)} />
			<div className="admin-page">
				<Topbar handleToggle={e => setToggled(!toggled)} />
				<div className="admin-content">
					<UserCompensation
						operatorId={admin?.operatorId}
						authtoken={token}
						stamp={admin?.stamp}
						etoken={admin?.token}
						point={admin?.loyalty_point}
						id={admin?.operatorId}
						role="operator"
						country={admin?.country}
						rewardHistoryPath="/admin/giveaway/history"
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		admin: state.admin.admin,
		token: state.admin.token
	};
};

export default connect(mapStateToProps, null)(Giveaway);
