import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Topbar from '../components/Topbar';
import Sidebar from "../components/Sidebar";
import Loading from '../../../admin/components/Loading';
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CSVLink } from "react-csv";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: null,
            loading: false,
            phone: "",
            country: ""
        };
    }

    componentDidMount() {
        if (!this.props.client || !this.props.client.user_detail) {
            return this.props.history.push('/client');
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    memberDetailsReport = (memberId, userId, country) => {
        this.props.history.push("/client/user/details/memberDetails", { memberId, userId, country });
    }

    handleSubmit = e => {
        e.preventDefault();
        
        const { phone } = this.state;

        if (phone) {

            this.setState({
                ...this.state,
                loading: true
            });
            axios
                .post("/api/admin/setting/getUsers", { operatorId: this.props.client.admin.map(admin => admin.id), phone: phone.replace(/ /g, '') }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data.data,
                        country: res.data.data.length > 0 ? res.data.data[0].country : ""
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        } else {
            mySwal.fire(i18n.t("Info"), i18n.t("Please fill in all the required fields"), "info");
        }

    };

    render() {
        let data = {
            columns: [
                {
                    label: i18n.t("Member Details Report"),
                    field: "report",
                    sort: "asc"
                },
                {
                    label: i18n.t("Distributor"),
                    field: "distributorName",
                    sort: "asc"
                },
                {
                    label: i18n.t("Name"),
                    field: "name",
                    sort: "asc"
                },
                {
                    label: i18n.t("Phone Number"),
                    field: "number",
                    sort: "asc"
                },
                {
                    label: i18n.t("Birthday"),
                    field: "birthday",
                    sort: "asc"
                },
                {
                    label: i18n.t("Age"),
                    field: "age",
                    sort: "asc"
                },
                {
                    label: i18n.t("Email Address"),
                    field: "email",
                    sort: "asc"
                },
                {
                    label: i18n.t("Member ID"),
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of vouchers collected")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Vouchers Acquired")}</span>
                        </OverlayTrigger>
                    ),
                    field: "totalVouchers",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total amount spent in washer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total Washer Payment")} {this.state.country ? `(${currency(this.state.country)})` : ""}</span>
                        </OverlayTrigger>
                    ),
                    field: "washerPayment",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total amount spent in dryer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total Dryer Payment")} {this.state.country ? `(${currency(this.state.country)})` : ""}</span>
                        </OverlayTrigger>
                    ),
                    field: "dryerPayment",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total amount spent in all transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total Payment")} {this.state.country ? `(${currency(this.state.country)})` : ""}</span>
                        </OverlayTrigger>
                    ),
                    field: "totalPayment",
                    sort: "asc"
                }
            ],
            rows: []
        };

        let csv = [];

        if (this.state.data) {
            for (let i = 0; i < this.state.data.length; i++) {
                const user = this.state.data[i];
                const dataObject = {
                    report: (
                        <button type="button" className="btn btn-link" onClick={e => this.memberDetailsReport(user.memberId, user.id, user.country)}>
                            {i18n.t("report")}
                        </button>
                    ),
                    distributorName: user.distributorName,
                    name: user.name,
                    number: user.number,
                    birthday: user.birthday,
                    age: user.age,
                    email: user.email,
                    memberId: user.memberId,
                    washerStamp: user.washerStamp,
                    dryerStamp: user.dryerStamp,
                    totalStamps: user.totalStamps,
                    totalVouchers: user.totalVouchers,
                    washerPayment: user.washerPayment,
                    dryerPayment: user.dryerPayment,
                    totalPayment: user.totalPayment,
                    token: user.token,
                    tokenPayment: user.tokenPayment,
                    tokenPurchased: user.tokenPurchased,
                    washerToken: user.washerToken,
                    dryerToken: user.dryerToken,
                    tokenUsed: user.tokenUsed
                };
                data.rows.push(dataObject);

                let csvObject = {
                    distributorName: this.state.data[i].distributorName,
                    name: this.state.data[i].name,
                    number: `=""${this.state.data[i].number}""`,
                    birthday: new Date(this.state.data[i].birthday).toLocaleDateString('en-GB'),
                    age: this.state.data[i].age,
                    email: this.state.data[i].email,
                    memberId: this.state.data[i].memberId,
                    washerStamp: this.state.data[i].washerStamp,
                    dryerStamp: this.state.data[i].dryerStamp,
                    totalStamps: this.state.data[i].totalStamps,
                    totalVouchers: this.state.data[i].totalVouchers,
                    washerPayment: this.state.data[i].washerPayment,
                    dryerPayment: this.state.data[i].dryerPayment,
                    totalPayment: this.state.data[i].totalPayment,
                    token: this.state.data[i].token,
                    tokenPayment: this.state.data[i].tokenPayment,
                    tokenPurchased: this.state.data[i].tokenPurchased,
                    washerToken: this.state.data[i].washerToken,
                    dryerToken: this.state.data[i].dryerToken,
                    tokenUsed: this.state.data[i].tokenUsed
                }
                csv.push(csvObject);
            }
        }

        const csvData = {
            headers: [
                { label: "Distributor", key: "distributorName" },
                { label: "Name", key: "name" },
                { label: "Phone Number", key: "number" },
                { label: "Birthday", key: "birthday" },
                { label: "Age", key: "age" },
                { label: "Email Address", key: "email" },
                { label: "Member ID", key: "memberId" },
                { label: "Vouchers Acquired", key: "totalVouchers" },
                { label: `Total Washer Payment ${this.state.country ? `(${currency(this.state.country)})` : ""}`, key: "washerPayment" },
                { label: `Total Dryer Payment ${this.state.country ? `(${currency(this.state.country)})` : ""}`, key: "dryerPayment" },
                { label: `Total Payment ${this.state.country ? `(${currency(this.state.country)})` : ""}`, key: "totalPayment" },
            ],
            data: csv
        };

        if (this.props.client.token) {
            data.columns = [
                ...data.columns,
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Current e-Token")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("e-Tokens")}</span>
                        </OverlayTrigger>
                    ),
                    field: "token",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total amount spent for buying e-Token")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Bought e-Token using")} {this.state.country ? `(${currency(this.state.country)})` : ""}</span>
                        </OverlayTrigger>
                    ),
                    field: "tokenPayment",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of e-Token purchased")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total e-Token Purchased (Tokens)")}</span>
                        </OverlayTrigger>
                    ),
                    field: "tokenPurchased",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of e-Token used in washer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Washer Payment (Tokens)")}</span>
                        </OverlayTrigger>
                    ),
                    field: "washerToken",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of e-Token used in dryer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Dryer Payment (Tokens)")}</span>
                        </OverlayTrigger>
                    ),
                    field: "dryerToken",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of e-Token used in all the transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total Payment (Tokens)")}</span>
                        </OverlayTrigger>
                    ),
                    field: "tokenUsed",
                    sort: "asc"
                }
            ];

            csvData.headers.push(
                { label: "e-Tokens", key: "token" },
                { label: `Bought e-Token using ${this.state.country ? `(${currency(this.state.country)})` : ""}`, key: "tokenPayment" },
                { label: "Total e-Token Purchased (Tokens)", key: "tokenPurchased" },
                { label: "Washer Payment (Tokens)", key: "washerToken" },
                { label: "Dryer Payment (Tokens)", key: "dryerToken" },
                { label: "Total Payment (Tokens)", key: "tokenUsed" }
            )
        }

        if (this.props.client.stamp) {
            data.columns = [
                ...data.columns,
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of washer stamp collected currently")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Washer Stamp")}</span>
                        </OverlayTrigger>
                    ),
                    field: "washerStamp",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of dryer stamp collected currently")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Dryer Stamp")}</span>
                        </OverlayTrigger>
                    ),
                    field: "dryerStamp",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of stamps collected")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total Stamps")}</span>
                        </OverlayTrigger>
                    ),
                    field: "totalStamps",
                    sort: "asc"
                },
            ];

            csvData.headers.push(
                { label: "Washer Stamp", key: "washerStamp" },
                { label: "Dryer Stamp", key: "dryerStamp" },
                { label: "Total Stamps", key: "totalStamps" },
            )
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        {this.state.loading ? (
                            <Loading />
                        ) : (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{i18n.t("User Details")}</h5>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="phone">{i18n.t("User Phone Number / Member ID / Email")}:</label>
                                            <input type="text" id="phone" name="phone" value={this.state.phone} onChange={this.handleChange} required placeholder={i18n.t("Example: 0123456789,A123456")} />
                                        </div>
                                        <input type="submit" value={i18n.t("SEARCH")} className="btn btn-primary form-control mb-1" />
                                    </form>

                                    <div className="text-center mt-5">{this.state.loading ? <Loading /> : (
                                        <div>
                                            <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`user_details_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                    {i18n.t("Download Report")}
                                                </CSVLink>
                                            </div>
                                        </div>
                                    )}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};
export default connect(mapStateToProps, null)(UserDetails);
