import { Switch, Route, Redirect } from "react-router-dom";

import Dashboard from "./Dashboard";
import TaskDetails from "./TaskDetails";
import StartTask from "./StartTask";

const StaffDashboard = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/staff/dashboard" component={Dashboard} />
				<Route exact path="/staff/task/details" component={TaskDetails} />
				<Route exact path="/staff/task/start" component={StartTask} />
				<Redirect to="/staff/dashboard" />
			</Switch>
		</div>
	);
};

export default StaffDashboard;