import { useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../utilities/Sidebar";
import { changeNav } from "../../../redux/actions/userActions";
import WashupTransaction from "./transaction/WashupTransaction";
import LaundroTransaction from "./transaction/LaundroTransaction";
import CleanproTransaction from "./transaction/CleanproTransaction";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Transaction = ({ transactions, reloads, transfers, user, changeNav, points, filter }) => {
  const [toggled, setToggled] = useState(false);
  const [selected, setSelected] = useState(filter ? filter : STORETYPE === "cleanpro" ? "All" : "Transaction");

  const renderDate = (date, timeOnly) => {
    const ascMonth = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const thisDate = new Date(date);

    let hour24 = "";
    let hour = "";

    if (thisDate.getUTCHours() >= 16) {
      hour24 = thisDate.getUTCHours() + 8 - 24;
    } else {
      hour24 = thisDate.getUTCHours() + 8;
    }

    if (hour24 > 12) {
      hour = hour24 - 12;
      if (hour.toString().length === 1) {
        hour = `0${hour}`
      }
    } else {
      hour = hour24;
      if (hour.toString().length === 1) {
        hour = `0${hour}`
      }
    }

    const minute = thisDate.getUTCMinutes().toString().length === 1 ? `0${thisDate.getUTCMinutes()}` : thisDate.getUTCMinutes().toString();

    const day = thisDate.getDate().toString().length === 1 ? `0${thisDate.getDate()}` : thisDate.getDate().toString();

    const month = ascMonth[thisDate.getMonth()];

    const year = thisDate.getFullYear();

    if (timeOnly) {
      return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"}`;
    } else {
      return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"} | ${day} ${month} ${year}`;
    }
  };

  return (
    <div id="transaction-page">
      <Sidebar toggled={toggled} setToggled={setToggled} />
      {
        STORETYPE === "washup" ? (
          <WashupTransaction user={user} setToggled={setToggled} selected={selected} setSelected={setSelected} renderDate={renderDate} transactions={transactions} reloads={reloads} transfers={transfers} points={points} />
        ) : STORETYPE === "cleanpro" ? (
          <CleanproTransaction user={user} changeNav={changeNav} filter={filter} selected={selected} setSelected={setSelected} renderDate={renderDate} transactions={transactions} reloads={reloads} transfers={transfers} points={points} />
        ) : (
          <LaundroTransaction user={user} setToggled={setToggled} selected={selected} setSelected={setSelected} renderDate={renderDate} transactions={transactions} reloads={reloads} transfers={transfers} points={points} />
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    transactions: state.user.user?.transactions || [],
    reloads: state.user.user?.tokenPurchaseHistory || [],
    transfers: state.user.user?.transferToken || [],
    points: state.user.user?.pointHistory || [],
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNav: (nav) => dispatch(changeNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
