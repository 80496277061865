import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Loading from "../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactQuill from 'react-quill';
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class EditFaq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            defaultLanguage: "en",
            image: null,
            imageUrl: null,
            loading: true,
            faqArray: [{ language: "en", question: "", answer: "" }],
            languageArray: [
                { value: "en", title: "English" },
                { value: "ms", title: "Malay" },
                { value: "zh", title: "Chinese (Simplified)" },
                { value: "zhtw", title: "Chinese (Traditional)" }
            ]
        };
    };

    componentDidMount() {
        if (!this.props.admin.admin || !this.props.location.state || !this.props.admin.admin.faq_setting) {
            this.props.history.push("/admin/dashboard");
        } else {
            axios
                .post("/api/admin/setting/getEditFaq", { faqId: this.props.location.state.faqId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        imageUrl: res.data.image,
                        faqArray: res.data.faq_langs,
                        defaultLanguage: res.data.language
                    });
                })
                .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => {

        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    removeFields = (index) => {
        let list = [...this.state.faqArray];
        list.splice(index, 1);
        this.setState({ ...this.state, faqArray: list })
    };

    addFields = () => {
        this.setState({ ...this.state, faqArray: [...this.state.faqArray, { language: "en", question: "", answer: "" }] })
    }

    handleImage = e => {

        const file = e.target.files[0];

        const imageRegex = /image\//g;
        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;

        if (file) {
            if (imageRegex.test(file.type)) {
                if (jpgRegex.test(file.name) || pngRegex.test(file.name) || jpegRegex.test(file.name)) {

                    if (file.size > 2097152) {
                        mySwal.fire(i18n.t("Info"), i18n.t("The image size is over 2MB"), "info");
                        e.target.value = "";
                    } else {
                        this.setState({
                            ...this.state,
                            image: file,
                            imageUrl: URL.createObjectURL(file)
                        });
                    }

                } else {
                    mySwal.fire(i18n.t("Info"), i18n.t("Please only use png, jpg or jpeg file extension type"), "info");
                    e.target.value = "";
                }
            } else {
                mySwal.fire(i18n.t("Info"), i18n.t("Please only use image type file"), "info");
                e.target.value = "";
            }
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        const { image, defaultLanguage, faqArray } = this.state;
        const { faqId } = this.props.location.state;

        const defaultFaq = faqArray.filter(faq => faq.language === defaultLanguage)[0]

        let submit = true
        let checkLanguage = []
        for (let i = 0; i < faqArray.length; i++) {
            if (!checkLanguage.includes(faqArray[i].language)) {
                checkLanguage.push(faqArray[i].language)
            } else {
                mySwal.fire(i18n.t("Error"), i18n.t("Duplicate language selected"), "error");
                submit = false
            }
        }

        if (submit) {
            if (defaultFaq) {
                mySwal.fire({
                    title: i18n.t("Confirmation"),
                    text: i18n.t("Are you sure you want to edit FAQ?"),
                    icon: "question",
                    showCancelButton: true,
                    cancelButtonText: i18n.t("No"),
                    confirmButtonText: i18n.t("Yes"),
                    cancelButtonColor: "#d33"
                })
                    .then(click => {

                        if (click.isConfirmed && click.value) {
                            this.setState({
                                ...this.state,
                                loading: true
                            });

                            const sendThis = {
                                question: defaultFaq.question,
                                answer: defaultFaq.answer,
                                faqId,
                                language: defaultLanguage,
                                faqArray
                            };

                            axios.post('/api/admin/setting/editFaq', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                                .then(res => {

                                    if (image) {
                                        const formData = new FormData();
                                        formData.append("file", image);
                                        formData.append("id", faqId);

                                        axios.post('/api/admin/setting/addFaqImage', formData, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                                            .then(res => {
                                                this.setState({
                                                    ...this.state,
                                                    image: null,
                                                    loading: false,
                                                });

                                                mySwal.fire(i18n.t("Success"), i18n.t("FAQ updated successfully"), "success");
                                                this.props.history.push("/admin/faq/list", { faqGroupId: this.props.location.state.faqGroupId, faqGroupTitle: this.props.location.state.faqGroupTitle });
                                            })
                                            .catch(err => {
                                                this.setState({
                                                    ...this.state,
                                                    loading: false
                                                });
                                                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                                            });
                                    } else {
                                        this.setState({
                                            ...this.state,
                                            loading: false,
                                        });

                                        mySwal.fire(i18n.t("Success"), i18n.t("FAQ updated successfully"), "success");
                                        this.props.history.push("/admin/faq/list", { faqGroupId: this.props.location.state.faqGroupId, faqGroupTitle: this.props.location.state.faqGroupTitle });
                                    }

                                })
                                .catch(err => {
                                    this.setState({
                                        ...this.state,
                                        loading: false
                                    });
                                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                                });
                        }
                    })
            } else {
                mySwal.fire(i18n.t("Error"), i18n.t("Please provide the faq with the default language"), "error");
            }
        }
    };

    render() {

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Edit FAQ")} {this.props.location.state.faqGroupTitle ? `(${this.props.location.state.faqGroupTitle})` : ""}</h5>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>

                                    <div className="form-group">
                                        <label htmlFor="defaultLanguage">{i18n.t("Default Language")} : </label><br />
                                        <select className="browser-default form-select" id="defaultLanguage" name="defaultLanguage" value={this.state.defaultLanguage} onChange={e => this.handleChange(e)} required>
                                            <option value="" disabled>{i18n.t("Select Default Language")}</option>
                                            {
                                                this.state.languageArray.map((language, index) => {
                                                    return (<option value={language.value} key={index}>{language.title}</option>)
                                                })
                                            }
                                        </select>
                                    </div>

                                    {
                                        this.state.imageUrl ? (
                                            <div style={{ maxWidth: "50%", display: "block", margin: "auto", textAlign: "center" }}>
                                                <img src={this.state.imageUrl} alt="" className="img-fluid" />
                                            </div>
                                        ) : null
                                    }

                                    <div className="form-group">
                                        <label htmlFor="image">{i18n.t("Image (Optional)")}: ({i18n.t("max")}: 2MB)</label><br />
                                        <input type="file" id="image" name="image" accept="image/*" onChange={this.handleImage} />
                                    </div>

                                    {
                                        this.state.faqArray.map((faq, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className="row mt-5">
                                                        <div className="col-4 m-auto">
                                                            <div className="form-group">
                                                                <label htmlFor="question">{i18n.t("Question")}: </label>
                                                                <input type="text" id="question" name="question" className="browser-default form-control" required value={this.state.faqArray[i].question} onChange={e => {
                                                                    let list = [...this.state.faqArray];
                                                                    list[i].question = e.target.value
                                                                    this.setState({ ...this.state, faqArray: list })
                                                                }} />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="language">{i18n.t("Language")} : </label>
                                                                <select className="browser-default form-select" id="language" name="language" required value={this.state.faqArray[i].language} onChange={e => {
                                                                    let list = [...this.state.faqArray];
                                                                    list[i].language = e.target.value
                                                                    this.setState({ ...this.state, faqArray: list })
                                                                }}>
                                                                    <option value="" disabled>{i18n.t("Select Language")}</option>
                                                                    {
                                                                        this.state.languageArray.map((language, index) => {
                                                                            return (<option value={language.value} key={index}>{language.title}</option>)
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <button type="button" className="btn btn-secondary" onClick={(e) => this.removeFields(i)}>
                                                                    {i18n.t("Remove")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col form-group">
                                                            <label htmlFor="answer">{i18n.t("Answer")}: </label>
                                                            <ReactQuill id="answer" name="answer" theme="snow" required value={this.state.faqArray[i].answer} onChange={e => {
                                                                let list = [...this.state.faqArray];
                                                                list[i].answer = e
                                                                this.setState({ ...this.state, faqArray: list })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        this.state.languageArray.length !== this.state.faqArray.length ? (
                                            <div className="form-group">
                                                <button type="button" className="btn btn-dark" onClick={(e) => this.addFields()}>
                                                    {i18n.t("Add Translation")}
                                                </button>
                                            </div>
                                        ) : null
                                    }

                                    <div className="mt-5">
                                        {
                                            this.state.loading
                                                ? <Loading />
                                                : (
                                                    <div>
                                                        <input type="submit" value={i18n.t("SUBMIT")} className="btn btn-primary form-control mb-1" />
                                                        <button type="button" className="btn btn-danger mt-1 form-control font-weight-bold" onClick={e => this.props.history.push("/admin/faq/list", { faqGroupId: this.props.location.state.faqGroupId, faqGroupTitle: this.props.location.state.faqGroupTitle })}>
                                                            {i18n.t("Cancel")}
                                                        </button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

export default connect(mapStateToProps, null)(EditFaq);